@use "sass:map";
@use "@angular/material" as mat;

@mixin theme-color-vars($theme) {
  //$palettes: map.get($theme, color);
  //$primary: map.get($palettes, primary);
  //$primary-contrast: map.get($primary, contrast);

  //$accent: map.get($palettes, accent);
  //$accent-contrast: map.get($accent, contrast);

  //$warn: map.get($palettes, warn);
  //$warn-contrast: map.get($warn, contrast);

  //$default-contrast: map.get($theme, default-contrast);
  //$default-primary-contrast: map.get($theme, primary-contrast);
  //$default-accent-contrast: map.get($theme, accent-contrast);
  //$default-warn-contrast: map.get($theme, warn-contrast);

  --dlc-color-primary-50: #{mat.get-theme-color($theme, primary, 0)};
  --dlc-color-primary-100: #{mat.get-theme-color($theme, primary, 10)};
  --dlc-color-primary-200: #{mat.get-theme-color($theme, primary, 20)};
  --dlc-color-primary-300: #{mat.get-theme-color($theme, primary, 30)};
  --dlc-color-primary-400: #{mat.get-theme-color($theme, primary, 40)};
  --dlc-color-primary-500: #{mat.get-theme-color($theme, primary, 50)};
  --dlc-color-primary-600: #{mat.get-theme-color($theme, primary, 60)};
  --dlc-color-primary-700: #{mat.get-theme-color($theme, primary, 70)};
  --dlc-color-primary-800: #{mat.get-theme-color($theme, primary, 80)};
  --dlc-color-primary-900: #{mat.get-theme-color($theme, primary, 90)};
  //--dlc-color-primary-A100: #{map.get($primary, A100)};
  //--dlc-color-primary-A200: #{map.get($primary, A200)};
  //--dlc-color-primary-A400: #{map.get($primary, A400)};
  //--dlc-color-primary-A700: #{map.get($primary, A700)};
  //--dlc-color-primary-contrast-50: #{map.get($primary-contrast, 50)};
  //--dlc-color-primary-contrast-100: #{map.get($primary-contrast, 100)};
  //--dlc-color-primary-contrast-200: #{map.get($primary-contrast, 200)};
  //--dlc-color-primary-contrast-300: #{map.get($primary-contrast, 300)};
  //--dlc-color-primary-contrast-400: #{map.get($primary-contrast, 400)};
  //--dlc-color-primary-contrast-500: #{map.get($primary-contrast, 500)};
  //--dlc-color-primary-contrast-600: #{map.get($primary-contrast, 600)};
  //--dlc-color-primary-contrast-700: #{map.get($primary-contrast, 700)};
  //--dlc-color-primary-contrast-800: #{map.get($primary-contrast, 800)};
  //--dlc-color-primary-contrast-900: #{map.get($primary-contrast, 900)};
  //--dlc-color-primary-contrast-A100: #{map.get($primary-contrast, A100)};
  //--dlc-color-primary-contrast-A200: #{map.get($primary-contrast, A200)};
  //--dlc-color-primary-contrast-A400: #{map.get($primary-contrast, A400)};
  //--dlc-color-primary-contrast-A700: #{map.get($primary-contrast, A700)};

  --dlc-color-accent-50: #{mat.get-theme-color($theme, secondary, 0)};
  --dlc-color-accent-100: #{mat.get-theme-color($theme, secondary, 10)};
  --dlc-color-accent-200: #{mat.get-theme-color($theme, secondary, 20)};
  --dlc-color-accent-300: #{mat.get-theme-color($theme, secondary, 30)};
  --dlc-color-accent-400: #{mat.get-theme-color($theme, secondary, 40)};
  --dlc-color-accent-500: #{mat.get-theme-color($theme, secondary, 50)};
  --dlc-color-accent-600: #{mat.get-theme-color($theme, secondary, 60)};
  --dlc-color-accent-700: #{mat.get-theme-color($theme, secondary, 70)};
  --dlc-color-accent-800: #{mat.get-theme-color($theme, secondary, 80)};
  --dlc-color-accent-900: #{mat.get-theme-color($theme, secondary, 90)};
  //--dlc-color-accent-A100: #{map.get($accent, A100)};
  //--dlc-color-accent-A200: #{map.get($accent, A200)};
  //--dlc-color-accent-A400: #{map.get($accent, A400)};
  //--dlc-color-accent-A700: #{map.get($accent, A700)};
  //--dlc-color-accent-contrast-50: #{map.get($accent-contrast, 50)};
  //--dlc-color-accent-contrast-100: #{map.get($accent-contrast, 100)};
  //--dlc-color-accent-contrast-200: #{map.get($accent-contrast, 200)};
  //--dlc-color-accent-contrast-300: #{map.get($accent-contrast, 300)};
  //--dlc-color-accent-contrast-400: #{map.get($accent-contrast, 400)};
  //--dlc-color-accent-contrast-500: #{map.get($accent-contrast, 500)};
  //--dlc-color-accent-contrast-600: #{map.get($accent-contrast, 600)};
  //--dlc-color-accent-contrast-700: #{map.get($accent-contrast, 700)};
  //--dlc-color-accent-contrast-800: #{map.get($accent-contrast, 800)};
  //--dlc-color-accent-contrast-900: #{map.get($accent-contrast, 900)};
  //--dlc-color-accent-contrast-A100: #{map.get($accent-contrast, A100)};
  //--dlc-color-accent-contrast-A200: #{map.get($accent-contrast, A200)};
  //--dlc-color-accent-contrast-A400: #{map.get($accent-contrast, A400)};
  //--dlc-color-accent-contrast-A700: #{map.get($accent-contrast, A700)};

  --dlc-color-warn-50: #{mat.get-theme-color($theme, tertiary, 0)};
  --dlc-color-warn-100: #{mat.get-theme-color($theme, tertiary, 10)};
  --dlc-color-warn-200: #{mat.get-theme-color($theme, tertiary, 20)};
  --dlc-color-warn-300: #{mat.get-theme-color($theme, tertiary, 30)};
  --dlc-color-warn-400: #{mat.get-theme-color($theme, tertiary, 40)};
  --dlc-color-warn-500: #{mat.get-theme-color($theme, tertiary, 50)};
  --dlc-color-warn-600: #{mat.get-theme-color($theme, tertiary, 60)};
  --dlc-color-warn-700: #{mat.get-theme-color($theme, tertiary, 70)};
  --dlc-color-warn-800: #{mat.get-theme-color($theme, tertiary, 80)};
  --dlc-color-warn-900: #{mat.get-theme-color($theme, tertiary, 90)};
  //--dlc-color-warn-A100: #{map.get($warn, A100)};
  //--dlc-color-warn-A200: #{map.get($warn, A200)};
  //--dlc-color-warn-A400: #{map.get($warn, A400)};
  //--dlc-color-warn-A700: #{map.get($warn, A700)};
  //--dlc-color-warn-contrast-50: #{map.get($warn-contrast, 50)};
  //--dlc-color-warn-contrast-100: #{map.get($warn-contrast, 100)};
  //--dlc-color-warn-contrast-200: #{map.get($warn-contrast, 200)};
  //--dlc-color-warn-contrast-300: #{map.get($warn-contrast, 300)};
  //--dlc-color-warn-contrast-400: #{map.get($warn-contrast, 400)};
  //--dlc-color-warn-contrast-500: #{map.get($warn-contrast, 500)};
  //--dlc-color-warn-contrast-600: #{map.get($warn-contrast, 600)};
  //--dlc-color-warn-contrast-700: #{map.get($warn-contrast, 700)};
  //--dlc-color-warn-contrast-800: #{map.get($warn-contrast, 800)};
  //--dlc-color-warn-contrast-900: #{map.get($warn-contrast, 900)};
  //--dlc-color-warn-contrast-A100: #{map.get($warn-contrast, A100)};
  //--dlc-color-warn-contrast-A200: #{map.get($warn-contrast, A200)};
  //--dlc-color-warn-contrast-A400: #{map.get($warn-contrast, A400)};
  //--dlc-color-warn-contrast-A700: #{map.get($warn-contrast, A700)};

  --dlc-color-primary: #{mat.get-theme-color($theme, primary, 50)};
  --dlc-color-accent: #{mat.get-theme-color($theme, secondary, 50)};
  --dlc-color-warn: #{mat.get-theme-color($theme, error, 50)};
  //--dlc-color-primary-contrast: #{$default-primary-contrast};
  //--dlc-color-accent-contrast: #{$default-accent-contrast};
  //--dlc-color-warn-contrast: #{$default-warn-contrast};
  //--dlc-color-text: #{mat.get-theme-color($theme, primary, 50)};
  //--dlc-color-default-contrast: #{mat.get-theme-color($theme, primary, 50)};
  //--dlc-color-text-accent: #{$default-accent-contrast};
  //--dlc-color-text-warn: #{$default-warn-contrast};

  --dlc-color-disabled: #757575;
  --dlc-color-text-disabled: #757575;
  --dlc-filled-text-field-disabled-label-text-color: #757575;

  // BUTTON
  --dlc-color-background-button-disabled: #757575;
  --dlc-color-background-button-none-hover: #b9b9b9;
  --dlc-color-button-disabled: #bdbdbd;
  --dlc-color-button-disabled-darker: #787878;

  // TRANSPARENT
  --dlc-color-transparent: rgba(255, 255, 255, 0.12);
  --dlc-color-transparent-hover: rgba(255, 255, 255, 0.16);
  --dlc-color-transparent-border: rgba(255, 255, 255, 0.24);

  // IMAGE CROPPER
  --cropper-outline-color: var(--dlc-color-transparent);
  --cropper-overlay-color: var(--dlc-color-transparent);
  --dlc-color-label: var(--dlc-color-border);

  --dlc-color-mat-dialog-background: rgba(0, 0, 0, 0.8);

  // FOOTER BAR
  --dlc-color-header-footer-background: rgb(255, 255, 255, 0.1);
}

@use "button/dlc-button-vars";

// Quiz
@use "quiz/ec-quiz";

// Include only once
// for all themes
@mixin dlc-all-base-component-vars {
  @import "@angular/cdk/overlay-prebuilt.css";
  @include dlc-button-vars.dlc-vars;
  @include ec-quiz.ec-quiz-vars;
}

@mixin ec-quiz-vars {
  /**
 NOTE: also used in libs/evolving-cognition/ui/src/lib/quiz/quiz-test/quiz-test-header/quiz-test-progress
 */
  .ec-quiz {
    --ec-quiz-padding: 4px;
    --ec-quiz-height: 24px;
    --ec-quiz-border-radius: 4px;
    --ec-quiz-font-size: 14px;
    --ec-quiz-font-weight: 300;
    --ec-quiz-background-color: #455a64; // mat.$blue-grey-palette, 700
    --ec-quiz-color: #fff;
    --ec-quiz-background-color-disabled: rgba(255, 255, 255, 0.12);
    --ec-quiz-color-disabled: rgba(255, 255, 255, 0.12);
    --ec-quiz-background-color-hover: #607d8b; // mat.$blue-grey-palette, 500
    --ec-quiz-background-color-active: #607d8b; // mat.$blue-grey-palette, 500
  }
}

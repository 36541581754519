@mixin light-theme-vars() {
  //--ng-pat-chart-background: hsla(0, 0%, 100%, 0.5);
  --ng-pat-chart-background-color: #ffffff;
  --ng-pat-chart-background-opacity: 0.25;
}

@mixin dark-theme-vars() {
  //--ng-pat-chart-background: hsla(0, 0%, 50%, 0.5); 8080807F
  --ng-pat-chart-background-color: #808080;
  --ng-pat-chart-background-opacity: 0.25;
}

@use "sass:map";
@use "sass:color";
@use "@angular/material" as mat;

@use "../../../../charts/theming" as charts;
@use "../../../../material" as ngpat-material;
//@use "./dlc-deprecated/_global-styles" as global;
@use "./dlc-theming";
@use "./ec-themes-configs";
@use "./ec/ec-global-styles" as ec-global-styles;

/**
In project.jspon targets.build.options:

"stylePreprocessorOptions": {
  "includePaths": [
    "libs/shared/theme/src/lib/theming"
  ]
}

 */

$no-density-theme: mat.define-theme(
  (
    density: (
      scale: 0
    )
  )
);

@mixin ec-theme-mixin() {
  /* You can add global styles to this file, and also import other style files */
  //@include mat.elevation-classes();
  //@include mat.app-background();

  body {
    @include ec-global-styles.ec-global-styles();
    overflow: hidden;
  }

  @include dlc-theming.dlc-core(
    (
      font-basis: 16
    )
  );

  // Angular Material
  @include mat.core();

  html {
    @include mat.all-component-themes(ec-themes-configs.$ec-personal-theme);
  }
  //@include mat.all-component-typographies(ec-themes-configs.$typography-config);

  // NGPAT Material

  /*
  @deprecated
   */
  //@include global.theme(ec-themes-configs.$ec-personal-theme);

  // Component base styles and vars
  @include dlc-theming.dlc-all-base-component-vars();

  @import "@syncfusion/ej2-base/styles/material3-dark.css";
  @import "@syncfusion/ej2-angular-base/styles/material3-dark.css";
  @import "@syncfusion/ej2-icons/styles/material3.css";
  @import "@syncfusion/ej2-richtexteditor/styles/material3-dark.css";
  @import "@syncfusion/ej2-buttons/styles/material3-dark.css";
  @import "@syncfusion/ej2-calendars/styles/material3-dark.css";
  @import "@syncfusion/ej2-dropdowns/styles/material3-dark.css";
  @import "@syncfusion/ej2-inputs/styles/material3-dark.css";
  @import "@syncfusion/ej2-navigations/styles/material3-dark.css";
  @import "@syncfusion/ej2-popups/styles/material3-dark.css";
  @import "@syncfusion/ej2-splitbuttons/styles/material3-dark.css";
  @import "@syncfusion/ej2-notifications/styles/material3-dark.css";
  @import "@syncfusion/ej2-lists/styles/material3-dark.css";
  @import "@syncfusion/ej2-navigations/styles/material3-dark.css";

  // primary mat.$blue-palette
  // accent mat.$pink-palette
  .ec-default-theme {
    @include charts.dark-theme-vars();
    //--dlc-color-primary: #2196f3;
    //--header-footer-background-color: rgba(255, 255, 255, 0.16);
    //--ec-color-header-footer-background: #132340;
    //--ec-color-header-footer-background: rgb(26, 33, 52);
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-personal-theme,
        primary,
        30
      )};

    @include dlc-theming.dlc-dark-theme-vars(
      ec-themes-configs.$ec-personal-theme
    );
    //@include mat.all-component-colors(ec-themes-configs.$ec-personal-theme);
    @include mat.form-field-density($no-density-theme);

    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-personal-theme,
      primary,
      50
    );

    // @syncfusion
    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-yellow
  // accent $ec-yellow-accent
  .ec-class-theme {
    @include mat.all-component-colors(ec-themes-configs.$ec-yellow-theme);
    //@include global.theme(ec-themes-configs.$ec-yellow-theme);

    //--ec-color-header-footer-background: #4f3920;
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-yellow-theme,
        primary,
        30
      )};
    --ec-color-background: #e7c69f; // 200
    --ec-color-list-background: rgba(231, 198, 159, 0.12);
    --ec-color-list-background-hover: rgba(231, 198, 159, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(
      ec-themes-configs.$ec-yellow-theme
    );
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-yellow-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-purple
  // accent $ec-purple-accent
  .ec-study-group-theme {
    @include mat.all-component-colors(ec-themes-configs.$ec-study-group-theme);

    //@include global.theme(ec-themes-configs.$ec-purple-theme);

    //--ec-color-header-footer-background: #2a1635;
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-study-group-theme,
        primary,
        30
      )};

    --ec-color-background: #cfc5cf; // 200
    --ec-color-list-background: rgba(207, 197, 207, 0.12);
    --ec-color-list-background-hover: rgba(207, 197, 207, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(
      ec-themes-configs.$ec-study-group-theme
    );
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-study-group-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-purple
  // accent $ec-purple-accent
  .ec-project-theme {
    @include mat.all-component-colors(ec-themes-configs.$ec-project-theme);

    //@include global.theme(ec-themes-configs.$ec-purple-theme);

    //--ec-color-header-footer-background: #2a1635;
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-project-theme,
        primary,
        10
      )};

    --ec-color-background: #cfc5cf; // 200
    --ec-color-list-background: rgba(207, 197, 207, 0.12);
    --ec-color-list-background-hover: rgba(207, 197, 207, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(
      ec-themes-configs.$ec-project-theme
    );
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-project-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-purple
  // accent $ec-purple-accent
  .ec-investigation-theme {
    @include mat.all-component-colors(
      ec-themes-configs.$ec-investigation-theme
    );

    //@include global.theme(ec-themes-configs.$ec-purple-theme);

    //--ec-color-header-footer-background: #2a1635;
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-investigation-theme,
        primary,
        30
      )};

    --ec-color-background: #cfc5cf; // 200
    --ec-color-list-background: rgba(207, 197, 207, 0.12);
    --ec-color-list-background-hover: rgba(207, 197, 207, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(
      ec-themes-configs.$ec-investigation-theme
    );
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-investigation-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-purple
  // accent $ec-purple-accent
  .ec-research-theme {
    @include mat.all-component-colors(ec-themes-configs.$ec-research-theme);

    //@include global.theme(ec-themes-configs.$ec-purple-theme);

    //--ec-color-header-footer-background: #2a1635;
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-research-theme,
        primary,
        30
      )};

    --ec-color-background: #cfc5cf; // 200
    --ec-color-list-background: rgba(207, 197, 207, 0.12);
    --ec-color-list-background-hover: rgba(207, 197, 207, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(
      ec-themes-configs.$ec-research-theme
    );
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-research-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-blue
  // accent $ec-blue-accent
  .ec-blue-theme {
    @include mat.all-component-colors(ec-themes-configs.$ec-blue-theme);
    //@include global.theme(ec-themes-configs.$ec-blue-theme);

    //--ec-color-header-footer-background: rgba(255, 255, 255, 0.16);
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-blue-theme,
        primary,
        30
      )};

    --ec-color-background: #a4afd4; // 200
    --ec-color-list-background: rgba(164, 175, 212, 0.12);
    --ec-color-list-background-hover: rgba(164, 175, 212, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(ec-themes-configs.$ec-blue-theme);
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-blue-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  // primary $ec-green
  // accent $ec-green-accent
  .ec-green-theme {
    @include mat.all-component-colors(ec-themes-configs.$ec-green-theme);
    //@include global.theme(ec-themes-configs.$ec-green-theme);

    //--ec-color-header-footer-background: rgba(255, 255, 255, 0.16);
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-green-theme,
        primary,
        30
      )};
    --ec-color-background: #9bd1c7; // 200
    --ec-color-list-background: rgba(155, 209, 199, 0.12);
    --ec-color-list-background-hover: rgba(155, 209, 199, 0.24);

    @include dlc-theming.dlc-dark-theme-vars(ec-themes-configs.$ec-green-theme);
    @include mat.form-field-density($no-density-theme);

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-green-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};
  }

  .ec-alert-theme {
    @include charts.dark-theme-vars();
    //--dlc-color-primary: #2196f3;
    //--header-footer-background-color: rgba(255, 255, 255, 0.16);
    //--ec-color-header-footer-background: #132340;
    //--ec-color-header-footer-background: rgb(26, 33, 52);
    --ec-color-header-footer-background: #{mat.get-theme-color(
        ec-themes-configs.$ec-error-theme,
        primary,
        30
      )};

    @include dlc-theming.dlc-dark-theme-vars(ec-themes-configs.$ec-error-theme);
    @include mat.all-component-colors(ec-themes-configs.$ec-error-theme);
    @include mat.form-field-density($no-density-theme);

    --mdc-icon-button-icon-color: #{mat.get-theme-color(
        ec-themes-configs.$ec-error-theme,
        primary,
        50
      )};

    --mat-menu-item-icon-color: #{mat.get-theme-color(
        ec-themes-configs.$ec-error-theme,
        primary,
        50
      )};
    --mat-badge-background-color: #{mat.get-theme-color(
        ec-themes-configs.$ec-error-theme,
        primary,
        50
      )};

    --mat-badge-text-color: #ffffff;

    // @syncfusion
    $primary: mat.get-theme-color(
      ec-themes-configs.$ec-error-theme,
      primary,
      50
    );

    --color-sf-primary-container: #{red($primary) green($primary) blue($primary)};

    .mat-badge-content {
      border: 1px solid white;
    }
  }
}

@use "sass:map";
@use "@angular/material" as mat;

/**
See https://material.angular.io/guide/theming-your-components
for theming docs
 */

@mixin style() {
}

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, "primary");
  $accent-palette: map.get($color-config, "accent");
  $warn-palette: map.get($color-config, "warn");
  $is-dark-theme: map.get($color-config, "is-dark");

  @if ($is-dark-theme) {
    .ng-pat-nav-item-link {
      a[href]:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }

      a.active {
        .mat-icon,
        .btn-char {
          color: mat.get-color-from-palette($primary-palette, 400);

          svg path {
            fill: mat.get-color-from-palette($primary-palette, 400);
          }
        }

        .link-title {
          color: mat.get-color-from-palette($primary-palette, 400);
        }
      }
    }
  } @else {
  }

  //.my-carousel-button {
  //  // Read the 500 hue from the primary color palette.
  //  color: mat.get-color-from-palette($primary-palette, 500);
  //}
}

@mixin typography($theme) {
  // Get the typography config from the theme.
  $typography-config: mat.get-typography-config($theme);

  //.my-carousel {
  //  font-family: mat.font-family($typography-config);
  //}
}

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.get-typography-config($theme);
  @if $typography-config != null {
    @include typography($theme);
  }
}

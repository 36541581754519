@use "sass:map";
@use "sass:math";

/**
  * Input a partial font config and get a full font config back.
  * If typography params are not provided in theme, set defaults.
  * @example
  * $font-config: (
  *   font-basis: 16,
  *   font-family: "Inter", sans-serif,
  *   base-font-size: 14px,
  *   base-line-height: 1.2rem,
  *   base-font-weight: 200,
  * )
  */
@function create-base-typography-config($font-config) {
  $font-basis: map.get($font-config, font-basis);

  @if ($font-basis == null) {
    $font-basis: 16;
  }

  $font-family: map.get($font-config, font-family);

  @if ($font-family == null) {
    $font-family: "Inter", sans-serif;
  }

  $font-weight: map.get($font-config, base-font-weight);

  @if ($font-weight == null) {
    $font-weight: 200;
  }

  $line-height: map.get($font-config, base-line-height);

  @if ($line-height == null) {
    $line-height: #{math.div(28, $font-basis)}rem; // 28px
  }

  $font-size: map.get($font-config, base-font-size);

  @if ($font-size == null) {
    $font-size: #{math.div(16, $font-basis)}rem; // 16px
  }

  $baseTypographyConfig: (
    font-basis: $font-basis,
    font-family: $font-family,
    base-font-size: $font-size,
    base-line-height: $line-height,
    base-font-weight: $font-weight
  );

  @return map.merge($baseTypographyConfig, $font-config);
}

@mixin base-typography-vars($font-config) {
  font-family: map.get($font-config, font-family);
  font-weight: map.get($font-config, base-font-weight);
  line-height: map.get($font-config, base-line-height);
  font-size: map.get($font-config, base-font-size);

  --dlc-base-font-family: #{map.get($font-config, font-family)};
  --dlc-base-font-size: #{map.get($font-config, base-font-size)};
  --dlc-base-font-weight: #{map.get($font-config, base-font-weight)};
  --dlc-base-line-height: #{map.get($font-config, base-line-height)};
}

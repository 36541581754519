

.e-badge {
  background: rgba(var(--color-sf-primary-container));
  border-color: transparent;
  border-radius: 2px;
  -webkit-box-shadow: 0 0 0 2px transparent;
          box-shadow: 0 0 0 2px transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: rgba(var(--color-sf-on-surface-variant));
  display: inline-block;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  padding: 2px 4px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  vertical-align: middle;
}
.e-badge:hover {
  text-decoration: none;
}
.e-badge.e-badge-pill {
  border-radius: 10px;
}
.e-badge.e-badge-notification {
  border-radius: 10px;
  height: 14px;
  line-height: 12px;
  min-width: 0;
  padding: 1px 4px;
  width: auto;
  font-size: 11px;
  position: absolute;
  top: -10px;
  left: 100%;
}
.e-badge.e-badge-notification.e-badge-ghost {
  line-height: 1;
}
.e-badge.e-badge-circle {
  border-radius: 10px;
  height: 16px;
  line-height: 12px;
  min-width: 0;
  padding: 2px 2px;
  width: 16px;
}
.e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1;
  padding: 2px;
}
.e-badge.e-badge-overlap {
  position: absolute;
  top: -10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.e-badge.e-badge-dot {
  border-radius: 10px;
  height: 10px;
  line-height: 1;
  min-width: 0;
  padding: 0;
  width: 10px;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0;
  overflow: visible;
  position: absolute;
  top: -3px;
  left: 100%;
}
.e-badge.e-badge-bottom.e-badge-dot {
  position: absolute;
  top: auto;
  bottom: 3px;
}
.e-badge.e-badge-bottom.e-badge-notification {
  position: absolute;
  top: auto;
  bottom: -3px;
}
button .e-badge {
  line-height: 0.9;
  position: relative;
  top: -2px;
}
button .e-badge.e-badge-circle {
  height: 18px;
  line-height: 1;
  width: 18px;
}
button .e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1;
}

.e-badge.e-badge-primary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-primary[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-primary));
  color: rgba(var(--color-sf-on-primary));
}
.e-badge.e-badge-secondary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-secondary[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-primary-container));
  color: rgba(var(--color-sf-on-primary-container));
}
.e-badge.e-badge-success:not(.e-badge-ghost):not([href]), .e-badge.e-badge-success[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-success-text));
}
.e-badge.e-badge-danger:not(.e-badge-ghost):not([href]), .e-badge.e-badge-danger[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-warning-text));
}
.e-badge.e-badge-warning:not(.e-badge-ghost):not([href]), .e-badge.e-badge-warning[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-warning-text));
}
.e-badge.e-badge-info:not(.e-badge-ghost):not([href]), .e-badge.e-badge-info[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-info-text));
}
.e-badge.e-badge-light:not(.e-badge-ghost):not([href]), .e-badge.e-badge-light[href]:not(.e-badge-ghost) {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-primary), 0.08)), to(rgba(var(--color-sf-primary), 0.08))), rgba(var(--color-sf-surface));
  background: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.08), rgba(var(--color-sf-primary), 0.08)), rgba(var(--color-sf-surface));
  color: rgba(var(--color-sf-on-surface));
}
.e-badge.e-badge-dark:not(.e-badge-ghost):not([href]), .e-badge.e-badge-dark[href]:not(.e-badge-ghost) {
  background-color: rgba(var(--color-sf-inverse-surface));
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-badge.e-badge-primary[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-secondary[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-success[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-danger[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-warning[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-info[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-light[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-dark[href]:not(.e-badge-ghost):hover {
  text-decoration: underline;
}
.e-badge.e-badge-ghost.e-badge-primary {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-primary));
  color: rgba(var(--color-sf-primary));
}
.e-badge.e-badge-ghost.e-badge-secondary {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-on-primary-container));
  color: rgba(var(--color-sf-on-primary-container));
  border: 1px solid rgba(var(--color-sf-primary-container));
  color: rgba(var(--color-sf-primary-container));
}
.e-badge.e-badge-ghost.e-badge-success {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-success));
}
.e-badge.e-badge-ghost.e-badge-danger {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-error));
}
.e-badge.e-badge-ghost.e-badge-warning {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-warning));
}
.e-badge.e-badge-ghost.e-badge-info {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-info));
}
.e-badge.e-badge-ghost.e-badge-light {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-on-surface));
  color: rgba(var(--color-sf-on-surface));
  border: 1px solid linear-gradient(0deg, rgba(var(--color-sf-primary), 0.08), rgba(var(--color-sf-primary), 0.08)), rgba(var(--color-sf-surface));
  color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.08), rgba(var(--color-sf-primary), 0.08)), rgba(var(--color-sf-surface));
}
.e-badge.e-badge-ghost.e-badge-dark {
  background-color: transparent;
  border: 1px solid rgba(var(--color-sf-inverse-surface));
  color: rgba(var(--color-sf-inverse-surface));
}

.e-bigger .e-badge,
.e-badge.e-bigger {
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  height: 16px;
  line-height: 12px;
  padding: 2px 3px;
}
.e-bigger .e-badge.e-badge-pill,
.e-badge.e-bigger.e-badge-pill {
  border-radius: 10px;
  padding: 2px 4px;
}
.e-bigger .e-badge.e-badge-ghost,
.e-badge.e-bigger.e-badge-ghost {
  padding: 2px;
}
.e-bigger .e-badge.e-badge-ghost.e-badge-circle,
.e-badge.e-bigger.e-badge-ghost.e-badge-circle {
  padding: 2px;
}
.e-bigger .e-badge.e-badge-ghost.e-badge-notification,
.e-badge.e-bigger.e-badge-ghost.e-badge-notification {
  padding: 3px 10px;
}
.e-bigger .e-badge.e-badge-notification,
.e-badge.e-bigger.e-badge-notification {
  border-radius: 10px;
  min-width: 0;
  padding: 2px 4px;
}
.e-bigger .e-badge.e-badge-circle,
.e-badge.e-bigger.e-badge-circle {
  border-radius: 10px;
  min-width: 0;
  padding: 2px 3px;
  width: 22px;
}
.e-bigger .e-badge.e-badge-dot,
.e-badge.e-bigger.e-badge-dot {
  border-radius: 10px;
  height: 12px;
  width: 12px;
}

.e-toast .e-toast-close-icon::before {
  content: "\e7e7";
}
.e-toast .e-toast-success-icon::before {
  content: "\e727";
}
.e-toast .e-toast-error-icon::before {
  content: "\e878";
}
.e-toast .e-toast-info-icon::before {
  content: "\e800";
}
.e-toast .e-toast-warning-icon::before {
  content: "\e88b";
}

/* stylelint-disable property-no-vendor-prefix */
.e-toast-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.e-toast-container.e-toast-top-left {
  left: 10px;
  top: 10px;
}
.e-toast-container.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}
.e-toast-container.e-toast-top-right {
  right: 10px;
  top: 10px;
}
.e-toast-container.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}
.e-toast-container.e-toast-bottom-center {
  bottom: 10px;
  pointer-events: none;
  right: 0;
  width: 100%;
}
.e-toast-container.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}
.e-toast-container.e-toast-top-center {
  pointer-events: none;
  right: 0;
  top: 10px;
  width: 100%;
}
.e-toast-container.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}
.e-toast-container.e-toast-full-width {
  left: 0;
  right: 0;
  width: 100%;
}
.e-toast-container.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
  width: 96%;
}
.e-toast-container.e-rtl .e-toast .e-toast-actions {
  text-align: left;
}
.e-toast-container.e-rtl .e-toast .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}
.e-toast-container.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}
.e-toast-container.e-rtl .e-toast .e-toast-icon {
  margin-left: 8px;
  margin-right: initial;
}
.e-toast-container.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}
.e-toast-container .e-toast {
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 14px;
  position: relative;
}
.e-toast-container .e-toast .e-toast-icon,
.e-toast-container .e-toast .e-toast-message {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.e-toast-container .e-toast > * {
  word-break: break-word;
  word-wrap: break-word;
}
.e-toast-container .e-toast .e-toast-message {
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
  width: inherit;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title,
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title:first-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title:last-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title > *,
.e-toast-container .e-toast .e-toast-message .e-toast-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-toast-container .e-toast .e-toast-message .e-toast-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  padding: 8px 0 18px 0;
  word-break: break-word;
  word-wrap: break-word;
}
.e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding-top: 0;
}
.e-toast-container .e-toast .e-toast-message .e-toast-actions {
  margin: 1px;
  padding: 14px 20px;
  text-align: right;
}
.e-toast-container .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}
.e-toast-container .e-toast .e-toast-close-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 20px;
  height: 32px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: auto;
  width: 32px;
}
.e-toast-container .e-toast .e-toast-icon {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
  height: 24px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 8px;
  width: 24px;
}
.e-toast-container .e-toast .e-toast-progress {
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
}

.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-blazor-toast-hidden {
  visibility: hidden;
}

.e-toast-container .e-toast .e-toast-close-icon.blazor-toast-close-icon {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  bottom: 6px;
  height: 20px;
  position: relative;
  width: 22px;
}

.e-toast-container .e-toast {
  background-color: rgba(var(--color-sf-inverse-surface));
  border: 0;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 4px 8px 3px rgba(0, 0, 0, 0.15);
  color: rgba(var(--color-sf-surface));
}
.e-toast-container .e-toast .e-toast-close-icon {
  color: rgba(var(--color-sf-on-primary));
}
.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:active {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: 0;
}
.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:hover {
  background: rgba(255, 255, 255, 0.18);
  color: rgba(var(--color-sf-on-primary));
}
.e-toast-container .e-toast .e-toast-close-icon.e-icons:hover,
.e-toast-container .e-toast .e-toast-close-icon.e-icons:focus {
  background: rgba(255, 255, 255, 0.18);
  border-radius: 50%;
  height: 32px;
  color: rgba(var(--color-sf-on-primary));
}
.e-toast-container .e-toast.e-toast-success {
  background-color: rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-success));
}
.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-title {
  color: rgba(var(--color-sf-success-text));
}
.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-content {
  color: rgba(var(--color-sf-success-text));
}
.e-toast-container .e-toast.e-toast-success .e-toast-icon {
  color: rgba(var(--color-sf-success-text));
}
.e-toast-container .e-toast.e-toast-success .e-toast-close-icon {
  color: rgba(var(--color-sf-success-text));
}
.e-toast-container .e-toast.e-toast-success:hover {
  background-color: rgba(var(--color-sf-success));
  -webkit-box-shadow: rgba(var(--color-sf-shadow));
          box-shadow: rgba(var(--color-sf-shadow));
  color: rgba(var(--color-sf-success));
}
.e-toast-container .e-toast.e-toast-info {
  background-color: rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-on-primary));
}
.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-title {
  color: rgba(var(--color-sf-info-text));
}
.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-content {
  color: rgba(var(--color-sf-info-text));
}
.e-toast-container .e-toast.e-toast-info .e-toast-icon {
  color: rgba(var(--color-sf-info-text));
}
.e-toast-container .e-toast.e-toast-info .e-toast-close-icon {
  color: rgba(var(--color-sf-info-text));
}
.e-toast-container .e-toast.e-toast-info:hover {
  background-color: rgba(var(--color-sf-info));
  -webkit-box-shadow: rgba(var(--color-sf-shadow));
          box-shadow: rgba(var(--color-sf-shadow));
  color: rgba(var(--color-sf-on-primary));
}
.e-toast-container .e-toast.e-toast-warning {
  background-color: rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-warning));
}
.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-title {
  color: rgba(var(--color-sf-warning-text));
}
.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-content {
  color: rgba(var(--color-sf-warning-text));
}
.e-toast-container .e-toast.e-toast-warning .e-toast-icon {
  color: rgba(var(--color-sf-warning-text));
}
.e-toast-container .e-toast.e-toast-warning .e-toast-close-icon {
  color: rgba(var(--color-sf-warning-text));
}
.e-toast-container .e-toast.e-toast-warning:hover {
  background-color: rgba(var(--color-sf-warning));
  -webkit-box-shadow: rgba(var(--color-sf-shadow));
          box-shadow: rgba(var(--color-sf-shadow));
  color: rgba(var(--color-sf-warning));
}
.e-toast-container .e-toast.e-toast-danger {
  background-color: rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-error));
}
.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-title {
  color: rgba(var(--color-sf-danger-text));
}
.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-content {
  color: rgba(var(--color-sf-danger-text));
}
.e-toast-container .e-toast.e-toast-danger .e-toast-icon {
  color: rgba(var(--color-sf-danger-text));
}
.e-toast-container .e-toast.e-toast-danger .e-toast-close-icon {
  color: rgba(var(--color-sf-danger-text));
}
.e-toast-container .e-toast.e-toast-danger:hover {
  background-color: rgba(var(--color-sf-error));
  -webkit-box-shadow: rgba(var(--color-sf-shadow));
          box-shadow: rgba(var(--color-sf-shadow));
  color: rgba(var(--color-sf-error));
}
.e-toast-container .e-toast:hover {
  background-color: rgba(var(--color-sf-inverse-surface));
  -webkit-box-shadow: rgba(var(--color-sf-shadow));
          box-shadow: rgba(var(--color-sf-shadow));
}
.e-toast-container .e-toast .e-toast-icon {
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-toast-container .e-toast .e-toast-message .e-toast-title {
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-toast-container .e-toast .e-toast-progress {
  background-color: rgba(var(--color-sf-primary));
}

.e-bigger .e-toast-container.e-toast-top-left,
.e-toast-container.e-bigger.e-toast-top-left {
  left: 10px;
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-left,
.e-toast-container.e-bigger.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}
.e-bigger .e-toast-container.e-toast-top-right,
.e-toast-container.e-bigger.e-toast-top-right {
  right: 10px;
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-right,
.e-toast-container.e-bigger.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-center,
.e-toast-container.e-bigger.e-toast-bottom-center {
  bottom: 10px;
}
.e-bigger .e-toast-container.e-toast-bottom-center .e-toast,
.e-toast-container.e-bigger.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-toast-top-center,
.e-toast-container.e-bigger.e-toast-top-center {
  top: 10px;
}
.e-bigger .e-toast-container.e-toast-top-center .e-toast,
.e-toast-container.e-bigger.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-toast-full-width,
.e-toast-container.e-bigger.e-toast-full-width {
  left: 0;
  right: 0;
}
.e-bigger .e-toast-container.e-toast-full-width .e-toast,
.e-toast-container.e-bigger.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions {
  text-align: left;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-icon {
  margin-left: 14px;
  margin-right: initial;
}
.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-progress,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}
.e-bigger .e-toast-container .e-toast,
.e-toast-container.e-bigger .e-toast {
  border-radius: 4px;
  font-size: 14px;
  margin: 0 0 10px;
  min-height: 48px;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content {
  padding: 8px 0 6px 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:first-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:last-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding: 0;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions {
  padding: 18px 28px;
  text-align: right;
}
.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}
.e-bigger .e-toast-container .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger .e-toast .e-toast-close-icon {
  font-size: 20px;
  height: 32px;
  width: 32px;
}
.e-bigger .e-toast-container .e-toast .e-toast-icon,
.e-toast-container.e-bigger .e-toast .e-toast-icon {
  font-size: 20px;
  height: 24px;
  margin-right: 14px;
  width: 24px;
}
.e-bigger .e-toast-container .e-toast .e-toast-progress,
.e-toast-container.e-bigger .e-toast .e-toast-progress {
  height: 4px;
}

.e-bigger .e-content-placeholder.e-toast.e-placeholder-toast,
.e-bigger.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-message .e-msg-icon::before,
.e-message .e-msg-close-icon::before {
  font-family: "e-icons";
}
.e-message .e-msg-icon::before, .e-message.e-info .e-msg-icon::before {
  content: "\e800";
}
.e-message .e-msg-close-icon::before {
  content: "\e7e7";
}
.e-message.e-success .e-msg-icon::before {
  content: "\e72b";
}
.e-message.e-error .e-msg-icon::before {
  content: "\e878";
}
.e-message.e-warning .e-msg-icon::before {
  content: "\e88b";
}

.e-message {
  border: 1px solid;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 7px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-message.e-hidden {
  display: none;
}
.e-message .e-msg-icon,
.e-message .e-msg-close-icon {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 14px;
}
.e-message .e-msg-icon {
  font-size: 14px;
  height: 14px;
  margin: 2px 6px;
}
.e-message .e-msg-close-icon {
  border-width: 0;
  cursor: pointer;
  font-size: 14px;
  margin: 0 4px 0 auto;
  padding: 2px;
  height: 18px;
}
.e-message .e-msg-content {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 12px;
  line-height: 1.5;
  padding: 0 6px;
  vertical-align: top;
}
.e-message.e-content-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.e-message.e-content-center .e-msg-content-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.e-message.e-content-right .e-msg-close-icon, .e-message.e-content-center .e-msg-close-icon {
  margin: 2px 6px;
}
.e-message.e-rtl .e-msg-close-icon {
  margin: 0 auto 0 4px;
}

.e-message {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-primary), 0.05)), to(rgba(var(--color-sf-primary), 0.05))), rgba(var(--color-sf-surface));
  background: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05)), rgba(var(--color-sf-surface));
  -o-border-image: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
     border-image: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-primary), 0.05)), to(rgba(var(--color-sf-primary), 0.05)));
     border-image: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
  color: rgba(var(--color-sf-on-surface));
}
.e-message .e-msg-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message .e-msg-close-icon {
  background: transparent;
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message .e-msg-close-icon:hover, .e-message .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
  border-radius: 50%;
}
.e-message.e-success {
  background-color: rgba(var(--color-sf-success-container));
  border-color: rgba(var(--color-sf-success-container));
  color: rgba(var(--color-sf-on-success-container));
}
.e-message.e-success .e-msg-icon {
  color: rgba(var(--color-sf-success));
}
.e-message.e-success .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-success .e-msg-close-icon:hover, .e-message.e-success .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-warning {
  background-color: rgba(var(--color-sf-warning-container));
  border-color: rgba(var(--color-sf-warning-container));
  color: rgba(var(--color-sf-on-warning-container));
}
.e-message.e-warning .e-msg-icon {
  color: rgba(var(--color-sf-warning));
}
.e-message.e-warning .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-warning .e-msg-close-icon:hover, .e-message.e-warning .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-info {
  background-color: rgba(var(--color-sf-info-container));
  border-color: rgba(var(--color-sf-info-container));
  color: rgba(var(--color-sf-on-info-container));
}
.e-message.e-info .e-msg-icon {
  color: rgba(var(--color-sf-info));
}
.e-message.e-info .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-info .e-msg-close-icon:hover, .e-message.e-info .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-error {
  background-color: rgba(var(--color-sf-error-container));
  border-color: rgba(var(--color-sf-error-container));
  color: rgba(var(--color-sf-on-error-container));
}
.e-message.e-error .e-msg-icon {
  color: rgba(var(--color-sf-error)) !important; /* stylelint-disable-line declaration-no-important */
}
.e-message.e-error .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-error .e-msg-close-icon:hover, .e-message.e-error .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-outlined {
  background: transparent;
  -o-border-image: none;
     border-image: none;
  border-color: rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-on-surface));
}
.e-message.e-outlined .e-msg-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-outlined .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-outlined .e-msg-close-icon:hover, .e-message.e-outlined .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-outlined.e-success {
  border-color: rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-on-success-container));
}
.e-message.e-outlined.e-success .e-msg-icon {
  color: rgba(var(--color-sf-on-success-container));
}
.e-message.e-outlined.e-success .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-outlined.e-success .e-msg-close-icon:hover, .e-message.e-outlined.e-success .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-outlined.e-warning {
  border-color: rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-on-warning-container));
}
.e-message.e-outlined.e-warning .e-msg-icon {
  color: rgba(var(--color-sf-on-warning-container));
}
.e-message.e-outlined.e-warning .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-outlined.e-warning .e-msg-close-icon:hover, .e-message.e-outlined.e-warning .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-outlined.e-info {
  border-color: rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-on-info-container));
}
.e-message.e-outlined.e-info .e-msg-icon {
  color: rgba(var(--color-sf-on-info-container));
}
.e-message.e-outlined.e-info .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-outlined.e-info .e-msg-close-icon:hover, .e-message.e-outlined.e-info .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-outlined.e-error {
  border-color: rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-on-error-container));
}
.e-message.e-outlined.e-error .e-msg-icon {
  color: rgba(var(--color-sf-on-error-container));
}
.e-message.e-outlined.e-error .e-msg-close-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-message.e-outlined.e-error .e-msg-close-icon:hover, .e-message.e-outlined.e-error .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-surface-variant), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-message.e-filled {
  background: rgba(var(--color-sf-inverse-surface));
  -o-border-image: none;
     border-image: none;
  border-color: rgba(var(--color-sf-inverse-surface));
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-message.e-filled .e-msg-icon {
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-message.e-filled .e-msg-close-icon {
  color: rgba(var(--color-sf-inverse-on-surface));
}
.e-message.e-filled .e-msg-close-icon:hover, .e-message.e-filled .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-inverse-on-surface), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-inverse-on-surface), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-inverse-on-surface), 0.12);
}
.e-message.e-filled.e-success {
  background-color: rgba(var(--color-sf-success));
  border-color: rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-on-success));
}
.e-message.e-filled.e-success .e-msg-icon {
  color: rgba(var(--color-sf-on-success));
}
.e-message.e-filled.e-success .e-msg-close-icon {
  color: rgba(var(--color-sf-on-success));
}
.e-message.e-filled.e-success .e-msg-close-icon:hover, .e-message.e-filled.e-success .e-msg-close-icon:focus {
  background-color: ragba(var(--color-sf-on-success), 0.12);
  -webkit-box-shadow: 0 0 0 2px ragba(var(--color-sf-on-success), 0.12);
          box-shadow: 0 0 0 2px ragba(var(--color-sf-on-success), 0.12);
}
.e-message.e-filled.e-warning {
  background-color: rgba(var(--color-sf-warning));
  border-color: rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-on-warning));
}
.e-message.e-filled.e-warning .e-msg-icon {
  color: rgba(var(--color-sf-on-warning));
}
.e-message.e-filled.e-warning .e-msg-close-icon {
  color: rgba(var(--color-sf-on-warning));
}
.e-message.e-filled.e-warning .e-msg-close-icon:hover, .e-message.e-filled.e-warning .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-warning), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-warning), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-warning), 0.12);
}
.e-message.e-filled.e-info {
  background-color: rgba(var(--color-sf-info));
  border-color: rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-on-info));
}
.e-message.e-filled.e-info .e-msg-icon {
  color: rgba(var(--color-sf-on-info));
}
.e-message.e-filled.e-info .e-msg-close-icon {
  color: rgba(var(--color-sf-on-info));
}
.e-message.e-filled.e-info .e-msg-close-icon:hover, .e-message.e-filled.e-info .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-info), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-info), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-info), 0.12);
}
.e-message.e-filled.e-error {
  background-color: rgba(var(--color-sf-error));
  border-color: rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-on-error));
}
.e-message.e-filled.e-error .e-msg-icon {
  color: rgba(var(--color-sf-on-error)) !important; /* stylelint-disable-line declaration-no-important */
}
.e-message.e-filled.e-error .e-msg-close-icon {
  color: rgba(var(--color-sf-on-error));
}
.e-message.e-filled.e-error .e-msg-close-icon:hover, .e-message.e-filled.e-error .e-msg-close-icon:focus {
  background-color: rgba(var(--color-sf-on-error), 0.12);
  -webkit-box-shadow: 0 0 0 2px rgba(var(--color-sf-on-error), 0.12);
          box-shadow: 0 0 0 2px rgba(var(--color-sf-on-error), 0.12);
}

.e-bigger .e-message,
.e-message.e-bigger {
  padding: 11px 9px;
}
.e-bigger .e-message .e-msg-icon,
.e-bigger .e-message .e-msg-close-icon,
.e-message.e-bigger .e-msg-icon,
.e-message.e-bigger .e-msg-close-icon {
  line-height: 16px;
}
.e-bigger .e-message .e-msg-icon,
.e-message.e-bigger .e-msg-icon {
  font-size: 16px;
  height: 16px;
  margin: 2px 6px;
}
.e-bigger .e-message .e-msg-close-icon,
.e-message.e-bigger .e-msg-close-icon {
  font-size: 16px;
  margin: 0 4px 0 auto;
  padding: 2px;
  height: 20px;
}
.e-bigger .e-message .e-msg-content,
.e-message.e-bigger .e-msg-content {
  line-height: 1.5;
  font-size: 14px;
  padding: 0 6px;
}
.e-bigger .e-message.e-content-right .e-msg-close-icon, .e-bigger .e-message.e-content-center .e-msg-close-icon,
.e-message.e-bigger.e-content-right .e-msg-close-icon,
.e-message.e-bigger.e-content-center .e-msg-close-icon {
  margin: 2px 6px;
}
.e-bigger .e-message.e-rtl .e-msg-close-icon,
.e-message.e-bigger.e-rtl .e-msg-close-icon {
  margin: 0 auto 0 4px;
}

.e-bigger .e-message .e-msg-close-icon:hover, .e-bigger .e-message .e-msg-close-icon:focus,
.e-message.e-bigger .e-msg-close-icon:hover,
.e-message.e-bigger .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-success .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-warning .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-info .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-error .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-outlined .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-outlined.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-success .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-outlined.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-warning .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-outlined.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-info .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-outlined.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-outlined.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-outlined.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-outlined.e-error .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-surface-variant), 0.12);
}
.e-bigger .e-message.e-filled .e-msg-close-icon:hover, .e-bigger .e-message.e-filled .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-inverse-on-surface), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-inverse-on-surface), 0.12);
}
.e-bigger .e-message.e-filled.e-success .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-success .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-success .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-success .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px ragba(var(--color-sf-on-success), 0.12);
          box-shadow: 0 0 0 4px ragba(var(--color-sf-on-success), 0.12);
}
.e-bigger .e-message.e-filled.e-warning .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-warning .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-warning .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-warning .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-warning), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-warning), 0.12);
}
.e-bigger .e-message.e-filled.e-info .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-info .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-info .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-info .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-info), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-info), 0.12);
}
.e-bigger .e-message.e-filled.e-error .e-msg-close-icon:hover, .e-bigger .e-message.e-filled.e-error .e-msg-close-icon:focus,
.e-message.e-bigger.e-filled.e-error .e-msg-close-icon:hover,
.e-message.e-bigger.e-filled.e-error .e-msg-close-icon:focus {
  -webkit-box-shadow: 0 0 0 4px rgba(var(--color-sf-on-error), 0.12);
          box-shadow: 0 0 0 4px rgba(var(--color-sf-on-error), 0.12);
}

.e-skeleton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: none;
}
.e-skeleton.e-skeleton-text, .e-skeleton.e-skeleton-square, .e-skeleton.e-skeleton-rectangle {
  border-radius: 2px;
}

.e-skeleton.e-skeleton-circle {
  border-radius: 50%;
}

.e-skeleton.e-visible-none {
  display: none;
}

.e-skeleton.e-shimmer-wave::after {
  -webkit-animation: e-shimmer-wave 2s infinite;
          animation: e-shimmer-wave 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(200% + 200px);
}

.e-skeleton.e-shimmer-fade {
  -webkit-animation: e-shimmer-fade 2s infinite;
          animation: e-shimmer-fade 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.e-skeleton.e-shimmer-pulse {
  -webkit-animation: e-shimmer-pulse 2s infinite;
          animation: e-shimmer-pulse 2s infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}

.e-rtl .e-shimmer-wave::after,
.e-rtl.e-shimmer-wave::after {
  -webkit-animation: e-shimmer-wave-rtl 2s infinite;
          animation: e-shimmer-wave-rtl 2s infinite;
}

@-webkit-keyframes e-shimmer-wave {
  to {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}

@keyframes e-shimmer-wave {
  to {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}
@-webkit-keyframes e-shimmer-wave-rtl {
  from {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}
@keyframes e-shimmer-wave-rtl {
  from {
    -webkit-transform: translateX(calc(50% + 100px));
            transform: translateX(calc(50% + 100px));
  }
}
@-webkit-keyframes e-shimmer-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes e-shimmer-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes e-shimmer-pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.975);
            transform: scale(0.975);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes e-shimmer-pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.975);
            transform: scale(0.975);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.e-skeleton.e-shimmer-wave::after {
  background-image: linear-gradient(90deg, transparent calc(50% - 100px), rgb(73, 69, 79) 50%, transparent calc(50% + 100px));
}

.e-skeleton.e-skeleton-text, .e-skeleton.e-skeleton-square, .e-skeleton.e-skeleton-rectangle, .e-skeleton.e-skeleton-circle {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-primary), 0.08)), to(rgba(var(--color-sf-primary), 0.08))), rgba(var(--color-sf-surface));
  background: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.08), rgba(var(--color-sf-primary), 0.08)), rgba(var(--color-sf-surface));
}
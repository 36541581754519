@mixin common-css-vars() {
  --dlc-border-radius: var(--8px);

  --ec-page-padding: 20px;
  // rgba(255, 255, 255, 0.12);
  --ec-color-transparent: rgba(255, 255, 255, 0.12);
  --ec-color-transparent-hover: rgba(255, 255, 255, 0.16);
  --ec-color-transparent-border: rgba(255, 255, 255, 0.24);
  --ec-color-logo-stroke: #ffffff;
  --ec-logo-stroke-opacity: 0.12;
  --ec-color-logo-fill: #ffffff;
  --ec-logo-fill-opacity: 1;
  --ec-logo-background-color: #ffffff;
  --ec-logo-background-opacity: 0.12;
  --ec-border-radius: 4px;

  --ec-color-background-button-disabled: rgba(255, 255, 255, 0.24);
  --ec-color-button-disabled: rgba(255, 255, 255, 0.65);

  //--ec-color-wrong-answer: #a44141;
  --ec-color-wrong-answer: #ff0c0c;
  --ec-color-background-wrong-answer: #1c0909;
  //--ec-color-correct-answer: #3c9994;
  --ec-color-correct-answer: #00ff38;
  --ec-color-background-correct-answer: #021f00;

  --ec-color-answer-0: #495ea8;
  --ec-color-answer-0-hover: #5568b0;
  --ec-color-answer-1: #8a3a9f;
  --ec-color-answer-1-hover: #914aa4;
  --ec-color-answer-2: #cf8d3f;
  --ec-color-answer-2-hover: #d79b58;
  --ec-color-answer-3: #36a28e;
  --ec-color-answer-3-hover: #4aab9c;
  --ec-color-answer-4: #592c64;
  --ec-color-answer-4-hover: #623a6d;
  --ec-color-answer-5: #455487;
  --ec-color-answer-5-hover: #576592;
  --ec-answer-min-height: 100px;

  --ec-color-mat-dialog-background: rgba(0, 0, 0, 0.75);

  --ec-disabled-opacity: 0.45;

  // https://developer.mozilla.org/en-US/docs/Web/CSS/transition
  --ec-transition-timing-function: ease-in-out;
  --ec-transition-duration: 200ms;
  --ec-transition-delay: 0ms;

  // FOOTER
  --dlc-footer-bar-height: var(--80px);
  --dlc-footer-bar-border-radius: var(--10px);
  --dlc-footer-bar-padding: var(--12px);
  --dlc-footer-bar-padding-top: var(--10px);
  --dlc-footer-bar-padding-bottom: var(--16px);
}

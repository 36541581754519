

/* stylelint-disable-line no-empty-source */
.e-dropdown-btn .e-caret::before,
.e-dropdown-btn.e-btn .e-caret::before {
  content: "\e70d";
}

.e-dropdown-btn,
.e-dropdown-btn.e-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
  text-transform: none;
  white-space: normal;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 7px 16px;
}
.e-dropdown-btn:focus,
.e-dropdown-btn.e-btn:focus {
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
.e-dropdown-btn:focus-visible,
.e-dropdown-btn.e-btn:focus-visible {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff;
}
.e-dropdown-btn:active,
.e-dropdown-btn.e-btn:active {
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
.e-dropdown-btn .e-btn-icon,
.e-dropdown-btn.e-btn .e-btn-icon {
  font-size: 16px;
}
.e-dropdown-btn .e-caret,
.e-dropdown-btn.e-btn .e-caret {
  font-size: 14px;
}
.e-dropdown-btn.e-vertical,
.e-dropdown-btn.e-btn.e-vertical {
  line-height: 1;
  padding: 6px 12px;
}
.e-dropdown-btn.e-caret-hide .e-caret,
.e-dropdown-btn.e-btn.e-caret-hide .e-caret {
  display: none;
}
.e-dropdown-btn.e-small .e-dropdown-popup ul,
.e-dropdown-btn.e-btn.e-small .e-dropdown-popup ul {
  padding: 4px 0;
}
.e-dropdown-btn.e-small .e-dropdown-popup ul .e-item,
.e-dropdown-btn.e-btn.e-small .e-dropdown-popup ul .e-item {
  padding: 0 8px;
  height: 24px;
}
.e-dropdown-btn.e-small.e-vertical,
.e-dropdown-btn.e-btn.e-small.e-vertical {
  line-height: 1;
  padding: 6px 12px;
}
.e-dropdown-popup {
  position: absolute;
}
.e-dropdown-popup ul {
  border: 0 solid rgba(var(--color-sf-outline-variant));
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  min-width: 120px;
  overflow: hidden;
  padding: 4px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}
.e-dropdown-popup ul .e-item {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  line-height: 32px;
  padding: 0 12px;
}
.e-dropdown-popup ul .e-item.e-separator {
  padding: 0 !important; /* stylelint-disable-line declaration-no-important */
}
.e-dropdown-popup ul .e-item.e-url {
  padding: 0;
}
.e-dropdown-popup ul .e-item .e-menu-url {
  display: block;
  padding: 0 12px;
  text-decoration: none;
  width: 100%;
}
.e-dropdown-popup ul .e-item .e-menu-icon {
  float: left;
  font-size: 16px;
  line-height: 32px;
  margin-right: 11px;
  vertical-align: middle;
  width: 1em;
}
.e-dropdown-popup ul .e-item.e-disabled {
  cursor: auto;
  pointer-events: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-dropdown-popup ul .e-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  cursor: auto;
  height: auto;
  line-height: normal;
  margin: 3px 0;
  pointer-events: none;
}
.e-dropdown-popup.e-transparent {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-dropdown-popup.e-transparent .e-dropdown-menu {
  border: 0;
  height: 1px;
  min-width: 0;
  padding: 0;
  width: 1px;
}
.e-dropdown-popup.e-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.e-rtl.e-dropdown-popup .e-item .e-menu-icon {
  float: right;
  margin-left: 11px;
  margin-right: 0;
}

.e-dropdown-popup-width ul {
  min-width: 0 !important; /* stylelint-disable-line declaration-no-important */
}

.e-dropdown-popup-width ul li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important; /* stylelint-disable-line declaration-no-important */
}

.e-dropdown-popup {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-primary), 0.11)), to(rgba(var(--color-sf-primary), 0.11))), rgba(var(--color-sf-surface));
  background: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.11), rgba(var(--color-sf-primary), 0.11)), rgba(var(--color-sf-surface));
  color: rgba(var(--color-sf-on-surface));
}
.e-dropdown-popup ul .e-item .e-menu-url {
  color: rgba(var(--color-sf-on-surface));
}
.e-dropdown-popup ul .e-item .e-menu-icon {
  color: rgba(var(--color-sf-on-surface-variant));
}
.e-dropdown-popup ul .e-item.e-focused {
  background: rgba(var(--color-sf-on-surface), 0.04);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-dropdown-popup ul .e-item:hover {
  background: rgba(var(--color-sf-on-surface), 0.05);
  color: rgba(var(--color-sf-on-surface));
}
.e-dropdown-popup ul .e-item:active, .e-dropdown-popup ul .e-item.e-selected {
  background: rgba(var(--color-sf-primary-container), 0.65);
  color: rgba(var(--color-sf-on-surface));
}
.e-dropdown-popup ul .e-item:active .e-menu-url, .e-dropdown-popup ul .e-item.e-selected .e-menu-url {
  color: rgba(var(--color-sf-on-surface));
}
.e-dropdown-popup ul .e-item:active .e-menu-icon, .e-dropdown-popup ul .e-item.e-selected .e-menu-icon {
  color: rgba(var(--color-sf-on-surface));
}
.e-dropdown-popup ul .e-separator {
  border-bottom-color: rgba(var(--color-sf-outline-variant));
}

.e-dropdown-btn.e-inherit,
.e-dropdown-btn.e-btn.e-inherit {
  background: inherit;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: inherit;
}
.e-dropdown-btn.e-inherit:hover, .e-dropdown-btn.e-inherit:focus, .e-dropdown-btn.e-inherit:active, .e-dropdown-btn.e-inherit.e-active,
.e-dropdown-btn.e-btn.e-inherit:hover,
.e-dropdown-btn.e-btn.e-inherit:focus,
.e-dropdown-btn.e-btn.e-inherit:active,
.e-dropdown-btn.e-btn.e-inherit.e-active {
  background: rgba(0, 0, 0, 0.056);
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: inherit;
}

.e-bigger .e-dropdown-btn .e-btn-icon,
.e-bigger.e-dropdown-btn .e-btn-icon {
  font-size: 18px;
}
.e-bigger .e-dropdown-btn .e-caret,
.e-bigger.e-dropdown-btn .e-caret {
  font-size: 16px;
}
.e-bigger .e-dropdown-btn.e-vertical,
.e-bigger.e-dropdown-btn.e-vertical {
  line-height: 1;
  padding: 8px 16px;
}
.e-bigger .e-dropdown-btn.e-small.e-vertical,
.e-bigger.e-dropdown-btn.e-small.e-vertical {
  line-height: 1;
  padding: 8px 16px;
}
*.e-bigger .e-dropdown-popup ul,
*.e-bigger.e-dropdown-popup ul {
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  font-size: 16px;
  min-width: 112px;
  padding: 8px 0;
}
*.e-bigger .e-dropdown-popup ul .e-item,
*.e-bigger.e-dropdown-popup ul .e-item {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}
*.e-bigger .e-dropdown-popup ul .e-item.e-url,
*.e-bigger.e-dropdown-popup ul .e-item.e-url {
  padding: 0;
}
*.e-bigger .e-dropdown-popup ul .e-item .e-menu-icon,
*.e-bigger.e-dropdown-popup ul .e-item .e-menu-icon {
  font-size: 20px;
  line-height: 40px;
}
*.e-bigger .e-dropdown-popup ul .e-item.e-separator,
*.e-bigger.e-dropdown-popup ul .e-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-split-btn-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
.e-split-btn-wrapper .e-split-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  text-transform: none;
  z-index: 1;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-split-btn:focus {
  outline-offset: 0;
}
.e-split-btn-wrapper .e-split-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-split-btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-split-btn:focus-visible {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff;
}
.e-split-btn-wrapper .e-split-btn:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 2;
}
.e-split-btn-wrapper .e-split-btn.e-top-icon-btn {
  padding-bottom: 10px;
  padding-top: 10px;
}
.e-split-btn-wrapper .e-split-btn .e-btn-icon {
  font-size: 16px;
  margin-left: 0;
  padding-right: 8px;
  width: auto;
  border-radius: 4px;
}
.e-split-btn-wrapper .e-split-btn .e-icon-top {
  padding-bottom: 4px;
  padding-right: 0;
}
.e-split-btn-wrapper .e-split-btn.e-icon-btn {
  padding-left: 10px;
  padding-right: 10px;
}
.e-split-btn-wrapper .e-split-btn.e-icon-btn .e-btn-icon {
  padding-right: 0;
}
.e-split-btn-wrapper .e-dropdown-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding-left: 4px;
  padding-right: 4px;
}
.e-split-btn-wrapper .e-dropdown-btn:focus, .e-split-btn-wrapper .e-dropdown-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-dropdown-btn:active {
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 2;
}
.e-split-btn-wrapper .e-dropdown-btn:disabled:focus, .e-split-btn-wrapper .e-dropdown-btn:disabled:hover, .e-split-btn-wrapper .e-dropdown-btn:disabled:active {
  z-index: 0;
}
.e-split-btn-wrapper .e-dropdown-btn:focus {
  outline-offset: 0;
}
.e-split-btn-wrapper .e-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-btn:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-btn:focus-visible {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff;
}
.e-split-btn-wrapper .e-btn:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-btn:disabled {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper .e-btn.e-inherit:focus, .e-split-btn-wrapper .e-btn.e-inherit:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper.e-rtl .e-split-btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 0;
  border-top-right-radius: 100px;
  margin-left: -1px;
  border-radius: 4px;
}
.e-split-btn-wrapper.e-rtl .e-split-btn .e-btn-icon {
  margin-right: 0;
  padding-left: 8px;
  padding-right: 0;
  border-radius: 4px;
}
.e-split-btn-wrapper.e-rtl .e-split-btn .e-icon-top {
  padding-left: 0;
}
.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn {
  padding-left: 10px;
  padding-right: 10px;
}
.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn .e-btn-icon {
  padding-left: 0;
}
.e-split-btn-wrapper.e-rtl .e-dropdown-btn {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 100px;
  border-top-right-radius: 0;
  margin-left: 0;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper.e-vertical {
  display: inline-block;
}
.e-split-btn-wrapper.e-vertical .e-split-btn,
.e-split-btn-wrapper.e-vertical .e-dropdown-btn {
  display: block;
  width: 100%;
}
.e-split-btn-wrapper.e-vertical .e-split-btn {
  border-bottom-left-radius: 0;
  border-top-right-radius: 100px;
  line-height: 1;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper.e-vertical .e-split-btn .e-icon-top {
  padding-bottom: 4px;
}
.e-split-btn-wrapper.e-vertical .e-dropdown-btn {
  border-bottom-left-radius: 100px;
  border-top-right-radius: 0;
  line-height: 0.334em;
  margin-left: 0;
  margin-top: -1px;
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper.e-vertical .e-dropdown-btn .e-icon-bottom {
  padding-top: 0;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn {
  border-bottom-right-radius: 0;
  border-top-left-radius: 100px;
  margin-left: 0;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-dropdown-btn {
  border-bottom-right-radius: 100px;
  border-top-left-radius: 0;
}

.e-split-btn-wrapper .e-split-btn {
  -o-border-image: linear-gradient(to right, rgba(var(--color-sf-surface), 1) 25%, rgba(var(--color-sf-outline-variant), 1) 75%) 1;
     border-image: -webkit-gradient(linear, left top, right top, color-stop(25%, rgba(var(--color-sf-surface), 1)), color-stop(75%, rgba(var(--color-sf-outline-variant), 1))) 1;
     border-image: linear-gradient(to right, rgba(var(--color-sf-surface), 1) 25%, rgba(var(--color-sf-outline-variant), 1) 75%) 1;
  border-width: 0 1px 0 0;
}
.e-split-btn-wrapper .e-split-btn:focus {
  border-right-color: rgba(var(--color-sf-outline-variant));
}
.e-split-btn-wrapper .e-split-btn:active {
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12)), rgba(var(--color-sf-surface));
}
.e-split-btn-wrapper .e-split-btn:disabled {
  border-right-color: rgba(var(--color-sf-secondary), 0.12);
}
.e-split-btn-wrapper .e-split-btn.e-inherit {
  border-color: transparent;
}
.e-split-btn-wrapper.e-splitbtn-disabled {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-split-btn-wrapper:hover :not(e-active) .e-split-btn {
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper:hover :not(e-active) .e-split-btn:disabled {
  border-right-color: rgba(var(--color-sf-secondary), 0);
}
.e-split-btn-wrapper.e-rtl .e-split-btn {
  -o-border-image: linear-gradient(to left, rgba(var(--color-sf-surface), 1) 25%, rgba(var(--color-sf-outline-variant), 1) 75%) 1;
     border-image: -webkit-gradient(linear, right top, left top, color-stop(25%, rgba(var(--color-sf-surface), 1)), color-stop(75%, rgba(var(--color-sf-outline-variant), 1))) 1;
     border-image: linear-gradient(to left, rgba(var(--color-sf-surface), 1) 25%, rgba(var(--color-sf-outline-variant), 1) 75%) 1;
  border-width: 0 0 0 1px;
}
.e-split-btn-wrapper.e-rtl .e-split-btn:focus {
  border-left-color: rgba(var(--color-sf-outline-variant));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-rtl .e-split-btn:active {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12)), rgba(var(--color-sf-surface));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12)), rgba(var(--color-sf-surface));
}
.e-split-btn-wrapper.e-rtl .e-split-btn:disabled {
  border-left-color: rgba(var(--color-sf-secondary), 0);
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-rtl .e-split-btn.e-inherit {
  border-color: transparent;
}
.e-split-btn-wrapper.e-rtl:hover .e-split-btn :not(e-active) {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-rtl:hover .e-split-btn :not(e-active):disabled {
  border-left-color: rgba(var(--color-sf-secondary), 0);
}
.e-split-btn-wrapper.e-vertical .e-split-btn {
  -o-border-image: linear-gradient(to bottom, rgba(var(--color-sf-surface), 1) 25%, rgba(var(--color-sf-outline-variant), 1) 75%) 1;
     border-image: -webkit-gradient(linear, left top, left bottom, color-stop(25%, rgba(var(--color-sf-surface), 1)), color-stop(75%, rgba(var(--color-sf-outline-variant), 1))) 1;
     border-image: linear-gradient(to bottom, rgba(var(--color-sf-surface), 1) 25%, rgba(var(--color-sf-outline-variant), 1) 75%) 1;
  border-width: 0 0 2px;
  border-bottom-color: rgba(var(--color-sf-outline-variant));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit, .e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit:focus, .e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit:active, .e-split-btn-wrapper.e-vertical .e-split-btn.e-inherit:disabled {
  border-color: transparent;
}
.e-split-btn-wrapper.e-vertical .e-split-btn:focus {
  border-bottom-color: rgba(var(--color-sf-outline-variant));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical .e-split-btn:active {
  border-bottom-color: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12)), rgba(var(--color-sf-surface));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12)), rgba(var(--color-sf-surface));
}
.e-split-btn-wrapper.e-vertical .e-split-btn:disabled {
  border-bottom-color: rgba(var(--color-sf-secondary), 0);
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical:hover .e-split-btn {
  border-bottom-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
  border-right-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical:hover .e-split-btn.e-inherit, .e-split-btn-wrapper.e-vertical:hover .e-split-btn.e-inherit:disabled {
  border-color: transparent;
}
.e-split-btn-wrapper.e-vertical:hover .e-split-btn:disabled {
  border-bottom-color: rgba(var(--color-sf-secondary), 0);
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit, .e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit:focus, .e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit:active, .e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn.e-inherit:disabled {
  border-color: transparent;
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn:focus {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn:active {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical.e-rtl .e-split-btn:disabled {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical.e-rtl:hover .e-split-btn {
  border-left-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-split-btn-wrapper.e-vertical.e-rtl:hover .e-split-btn.e-inherit {
  border-color: transparent;
}

.e-bigger .e-split-btn-wrapper,
.e-bigger.e-split-btn-wrapper {
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
.e-bigger .e-split-btn-wrapper .e-split-btn,
.e-bigger.e-split-btn-wrapper .e-split-btn {
  border-radius: 4px;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding-left: 12px;
  padding-right: 6px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn.e-top-icon-btn,
.e-bigger.e-split-btn-wrapper .e-split-btn.e-top-icon-btn {
  padding-bottom: 12px;
  padding-top: 12px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper .e-split-btn .e-btn-icon {
  font-size: 18px;
  margin-left: 0;
  padding-right: 10px;
  width: auto;
  border-radius: 4px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn .e-icon-top,
.e-bigger.e-split-btn-wrapper .e-split-btn .e-icon-top {
  padding-bottom: 6px;
  padding-right: 0;
}
.e-bigger .e-split-btn-wrapper .e-split-btn.e-icon-btn,
.e-bigger.e-split-btn-wrapper .e-split-btn.e-icon-btn {
  padding-left: 12px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper .e-split-btn.e-icon-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper .e-split-btn.e-icon-btn .e-btn-icon {
  padding-right: 0;
}
.e-bigger .e-split-btn-wrapper .e-dropdown-btn,
.e-bigger.e-split-btn-wrapper .e-dropdown-btn {
  padding-left: 4px;
  padding-right: 4px;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn {
  padding-left: 6px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn .e-btn-icon {
  padding-left: 10px;
  padding-right: 0;
  border-radius: 4px;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn .e-icon-top,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn .e-icon-top {
  padding-left: 0;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn {
  padding-left: 12px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn .e-btn-icon,
.e-bigger.e-split-btn-wrapper.e-rtl .e-split-btn.e-icon-btn .e-btn-icon {
  padding-left: 0;
}
.e-bigger .e-split-btn-wrapper.e-vertical .e-split-btn,
.e-bigger.e-split-btn-wrapper.e-vertical .e-split-btn {
  padding-bottom: 6px;
  padding-right: 12px;
}
.e-bigger .e-split-btn-wrapper.e-vertical .e-dropdown-btn,
.e-bigger.e-split-btn-wrapper.e-vertical .e-dropdown-btn {
  padding-bottom: 4px;
  padding-top: 4px;
}
.e-bigger .e-split-btn-wrapper.e-vertical .e-dropdown-btn .e-icon-bottom,
.e-bigger.e-split-btn-wrapper.e-vertical .e-dropdown-btn .e-icon-bottom {
  padding-top: 0;
}

/* stylelint-disable */
*.e-btn-group,
*.e-css.e-btn-group {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 4px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  position: relative;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}
*.e-btn-group input + label.e-btn,
*.e-css.e-btn-group input + label.e-btn {
  margin-bottom: 0;
}
*.e-btn-group input,
*.e-css.e-btn-group input {
  -moz-appearance: none;
  height: 1px;
  margin: 0 0 0 -1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}
*.e-btn-group input:disabled + label.e-btn,
*.e-btn-group :disabled,
*.e-css.e-btn-group input:disabled + label.e-btn,
*.e-css.e-btn-group :disabled {
  cursor: default;
  pointer-events: none;
}
*.e-btn-group .e-btn-icon,
*.e-css.e-btn-group .e-btn-icon {
  font-size: 16px;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:not(:first-of-type):not(:last-of-type),
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:first-of-type, *.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:first-of-type,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-btn:last-of-type {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper + .e-btn,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper + .e-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:first-child .e-btn:last-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:first-child .e-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:last-child .e-btn:first-child,
*.e-css.e-btn-group:not(.e-rtl):not(.e-vertical) .e-split-btn-wrapper:last-child .e-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-btn,
*.e-css.e-btn-group.e-rtl .e-btn {
  margin-left: -1px;
}
*.e-btn-group.e-rtl .e-btn:not(:first-of-type):not(:last-of-type),
*.e-css.e-btn-group.e-rtl .e-btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
*.e-btn-group.e-rtl .e-btn:first-of-type, *.e-btn-group.e-rtl .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group.e-rtl .e-btn:first-of-type,
*.e-css.e-btn-group.e-rtl .e-btn:last-of-type:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-btn:last-of-type,
*.e-css.e-btn-group.e-rtl .e-btn:last-of-type {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper + .e-btn,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper + .e-btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:not(:first-child):not(:last-child) .e-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:first-child .e-btn:last-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:first-child .e-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-rtl .e-split-btn-wrapper:last-child .e-btn:first-child,
*.e-css.e-btn-group.e-rtl .e-split-btn-wrapper:last-child .e-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-vertical,
*.e-css.e-btn-group.e-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
*.e-btn-group.e-vertical input,
*.e-css.e-btn-group.e-vertical input {
  margin: -1px 0 0;
}
*.e-btn-group.e-vertical .e-btn:not(.e-outline),
*.e-css.e-btn-group.e-vertical .e-btn:not(.e-outline) {
  margin-top: -1px;
}
*.e-btn-group.e-vertical .e-btn:not(:first-of-type):not(:last-of-type),
*.e-css.e-btn-group.e-vertical .e-btn:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}
*.e-btn-group.e-vertical .e-btn:first-of-type,
*.e-css.e-btn-group.e-vertical .e-btn:first-of-type {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
*.e-btn-group.e-vertical .e-btn:last-of-type,
*.e-css.e-btn-group.e-vertical .e-btn:last-of-type {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) {
  border-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:first-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:first-of-type {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-btn:last-of-type:not(:last-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper + .e-btn,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper + .e-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:first-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:first-child {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:last-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:first-child .e-btn:last-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:first-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:last-child,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical):not(.e-rtl) .e-split-btn-wrapper:last-child .e-btn:last-child {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl {
  border-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type,
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type:not(:last-child),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:last-of-type:not(:last-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
*.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type:not(:first-child),
*.e-css.e-btn-group.e-round-corner:not(.e-vertical).e-rtl .e-btn:first-of-type:not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

/* stylelint-disable */
.e-btn-group .e-btn,
.e-css.e-btn-group .e-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:hover,
.e-css.e-btn-group .e-btn:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:focus-visible,
.e-btn-group input:focus-visible + label.e-btn,
.e-css.e-btn-group .e-btn:focus-visible,
.e-css.e-btn-group input:focus-visible + label.e-btn {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
  z-index: 1;
}
.e-btn-group .e-btn:focus-visible.e-primary, .e-btn-group .e-btn:focus-visible.e-success, .e-btn-group .e-btn:focus-visible.e-info, .e-btn-group .e-btn:focus-visible.e-warning, .e-btn-group .e-btn:focus-visible.e-danger,
.e-btn-group input:focus-visible + label.e-btn.e-primary,
.e-btn-group input:focus-visible + label.e-btn.e-success,
.e-btn-group input:focus-visible + label.e-btn.e-info,
.e-btn-group input:focus-visible + label.e-btn.e-warning,
.e-btn-group input:focus-visible + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:focus-visible.e-primary,
.e-css.e-btn-group .e-btn:focus-visible.e-success,
.e-css.e-btn-group .e-btn:focus-visible.e-info,
.e-css.e-btn-group .e-btn:focus-visible.e-warning,
.e-css.e-btn-group .e-btn:focus-visible.e-danger,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-primary,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-success,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-info,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-warning,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-danger {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
  z-index: 1;
}
.e-btn-group .e-btn:focus-visible.e-outline,
.e-btn-group input:focus-visible + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:focus-visible.e-outline,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
  z-index: 1;
}
.e-btn-group .e-btn:focus-visible.e-outline.e-primary, .e-btn-group .e-btn:focus-visible.e-outline.e-success, .e-btn-group .e-btn:focus-visible.e-outline.e-info, .e-btn-group .e-btn:focus-visible.e-outline.e-warning, .e-btn-group .e-btn:focus-visible.e-outline.e-danger,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-primary,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-success,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-info,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-warning,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:focus-visible.e-outline.e-primary,
.e-css.e-btn-group .e-btn:focus-visible.e-outline.e-success,
.e-css.e-btn-group .e-btn:focus-visible.e-outline.e-info,
.e-css.e-btn-group .e-btn:focus-visible.e-outline.e-warning,
.e-css.e-btn-group .e-btn:focus-visible.e-outline.e-danger,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-success,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-info,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-danger {
  -webkit-box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
          box-shadow: 0 0 0 1px #000, 0 0 0 3px #fff !important;
  z-index: 1;
}
.e-btn-group .e-btn:focus,
.e-btn-group input:focus-visible + label.e-btn,
.e-btn-group input:focus + label.e-btn,
.e-css.e-btn-group .e-btn:focus,
.e-css.e-btn-group input:focus-visible + label.e-btn,
.e-css.e-btn-group input:focus + label.e-btn {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-surface), 0.08)), to(rgba(var(--color-sf-on-surface), 0.08))), rgba(var(--color-sf-surface));
  background: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.08), rgba(var(--color-sf-on-surface), 0.08)), rgba(var(--color-sf-surface));
  -o-border-image: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.08), rgba(var(--color-sf-on-surface), 0.08));
     border-image: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-surface), 0.08)), to(rgba(var(--color-sf-on-surface), 0.08)));
     border-image: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.08), rgba(var(--color-sf-on-surface), 0.08));
  color: rgba(var(--color-sf-on-surface));
  outline: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05)), rgba(var(--color-sf-surface)) 0 solid;
  outline-offset: 0;
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-primary,
.e-btn-group input:focus-visible + label.e-btn.e-primary,
.e-btn-group input:focus + label.e-btn.e-primary,
.e-css.e-btn-group .e-btn:focus.e-primary,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-primary,
.e-css.e-btn-group input:focus + label.e-btn.e-primary {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-primary), 0.08)), to(rgba(var(--color-sf-on-primary), 0.08))), rgba(var(--color-sf-primary));
  background: linear-gradient(0deg, rgba(var(--color-sf-on-primary), 0.08), rgba(var(--color-sf-on-primary), 0.08)), rgba(var(--color-sf-primary));
  border-color: rgba(var(--color-sf-primary));
  color: rgba(var(--color-sf-on-primary));
  outline: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05)), rgba(var(--color-sf-surface)) 0 solid;
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-success,
.e-btn-group input:focus-visible + label.e-btn.e-success,
.e-btn-group input:focus + label.e-btn.e-success,
.e-css.e-btn-group .e-btn:focus.e-success,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-success,
.e-css.e-btn-group input:focus + label.e-btn.e-success {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-success-text), 0.08)), to(rgba(var(--color-sf-success-text), 0.08))), rgba(var(--color-sf-success));
  background: linear-gradient(0deg, rgba(var(--color-sf-success-text), 0.08), rgba(var(--color-sf-success-text), 0.08)), rgba(var(--color-sf-success));
  border-color: rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-success-text));
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-info,
.e-btn-group input:focus-visible + label.e-btn.e-info,
.e-btn-group input:focus + label.e-btn.e-info,
.e-css.e-btn-group .e-btn:focus.e-info,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-info,
.e-css.e-btn-group input:focus + label.e-btn.e-info {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-info-text), 0.08)), to(rgba(var(--color-sf-info-text), 0.08))), rgba(var(--color-sf-info));
  background: linear-gradient(0deg, rgba(var(--color-sf-info-text), 0.08), rgba(var(--color-sf-info-text), 0.08)), rgba(var(--color-sf-info));
  border-color: rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-info-text));
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-warning,
.e-btn-group input:focus-visible + label.e-btn.e-warning,
.e-btn-group input:focus + label.e-btn.e-warning,
.e-css.e-btn-group .e-btn:focus.e-warning,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-warning,
.e-css.e-btn-group input:focus + label.e-btn.e-warning {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-warning-text), 0.08)), to(rgba(var(--color-sf-warning-text), 0.08))), rgba(var(--color-sf-warning));
  background: linear-gradient(0deg, rgba(var(--color-sf-warning-text), 0.08), rgba(var(--color-sf-warning-text), 0.08)), rgba(var(--color-sf-warning));
  border-color: rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-warning-text));
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-danger,
.e-btn-group input:focus-visible + label.e-btn.e-danger,
.e-btn-group input:focus + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:focus.e-danger,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-danger,
.e-css.e-btn-group input:focus + label.e-btn.e-danger {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-danger-text), 0.08)), to(rgba(var(--color-sf-danger-text), 0.08))), rgba(var(--color-sf-error));
  background: linear-gradient(0deg, rgba(var(--color-sf-danger-text), 0.08), rgba(var(--color-sf-danger-text), 0.08)), rgba(var(--color-sf-error));
  border-color: rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-danger-text));
  -webkit-box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
  outline-color: inherit;
}
.e-btn-group .e-btn:focus.e-link,
.e-btn-group input:focus-visible + label.e-btn.e-link,
.e-btn-group input:focus + label.e-btn.e-link,
.e-css.e-btn-group .e-btn:focus.e-link,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-link,
.e-css.e-btn-group input:focus + label.e-btn.e-link {
  border-radius: 0;
  text-decoration: underline;
  color: rgba(var(--color-sf-info));
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
.e-btn-group .e-btn:focus.e-outline,
.e-btn-group input:focus-visible + label.e-btn.e-outline,
.e-btn-group input:focus + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:focus.e-outline,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline,
.e-css.e-btn-group input:focus + label.e-btn.e-outline {
  background: rgba(var(--color-sf-on-surface), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-on-surface));
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:focus.e-outline.e-primary,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-primary,
.e-btn-group input:focus + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group .e-btn:focus.e-outline.e-primary,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-primary {
  background: rgba(var(--color-sf-primary), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-primary));
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:focus.e-outline.e-success,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-success,
.e-btn-group input:focus + label.e-btn.e-outline.e-success,
.e-css.e-btn-group .e-btn:focus.e-outline.e-success,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-success,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-success {
  background: rgba(var(--color-sf-success), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-success));
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:focus.e-outline.e-info,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-info,
.e-btn-group input:focus + label.e-btn.e-outline.e-info,
.e-css.e-btn-group .e-btn:focus.e-outline.e-info,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-info,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-info {
  background: rgba(var(--color-sf-info), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-info));
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:focus.e-outline.e-warning,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-warning,
.e-btn-group input:focus + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group .e-btn:focus.e-outline.e-warning,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-warning {
  background: rgba(var(--color-sf-warning), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-warning));
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:focus.e-outline.e-danger,
.e-btn-group input:focus-visible + label.e-btn.e-outline.e-danger,
.e-btn-group input:focus + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:focus.e-outline.e-danger,
.e-css.e-btn-group input:focus-visible + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group input:focus + label.e-btn.e-outline.e-danger {
  background: rgba(var(--color-sf-error), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-error));
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active,
.e-btn-group input:active + label.e-btn,
.e-btn-group input:checked + label.e-btn,
.e-css.e-btn-group .e-btn:active,
.e-css.e-btn-group input:active + label.e-btn,
.e-css.e-btn-group input:checked + label.e-btn {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-surface), 0.12)), to(rgba(var(--color-sf-on-surface), 0.12))), rgba(var(--color-sf-surface));
  background: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12)), rgba(var(--color-sf-surface));
  -o-border-image: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12));
     border-image: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-surface), 0.12)), to(rgba(var(--color-sf-on-surface), 0.12)));
     border-image: linear-gradient(0deg, rgba(var(--color-sf-on-surface), 0.12), rgba(var(--color-sf-on-surface), 0.12));
  color: rgba(var(--color-sf-on-surface));
  outline: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05)), rgba(var(--color-sf-surface)) 0 solid;
  outline-offset: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active.e-primary,
.e-btn-group input:active + label.e-btn.e-primary,
.e-btn-group input:checked + label.e-btn.e-primary,
.e-css.e-btn-group .e-btn:active.e-primary,
.e-css.e-btn-group input:active + label.e-btn.e-primary,
.e-css.e-btn-group input:checked + label.e-btn.e-primary {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-primary), 0.12)), to(rgba(var(--color-sf-on-primary), 0.12))), rgba(var(--color-sf-primary));
  background: linear-gradient(0deg, rgba(var(--color-sf-on-primary), 0.12), rgba(var(--color-sf-on-primary), 0.12)), rgba(var(--color-sf-primary));
  border-color: rgba(var(--color-sf-primary));
  color: rgba(var(--color-sf-on-primary));
  outline: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05)), rgba(var(--color-sf-surface)) 0 solid;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active.e-success,
.e-btn-group input:active + label.e-btn.e-success,
.e-btn-group input:checked + label.e-btn.e-success,
.e-css.e-btn-group .e-btn:active.e-success,
.e-css.e-btn-group input:active + label.e-btn.e-success,
.e-css.e-btn-group input:checked + label.e-btn.e-success {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-success-text), 0.12)), to(rgba(var(--color-sf-success-text), 0.12))), rgba(var(--color-sf-success));
  background: linear-gradient(0deg, rgba(var(--color-sf-success-text), 0.12), rgba(var(--color-sf-success-text), 0.12)), rgba(var(--color-sf-success));
  border-color: rgba(var(--color-sf-success));
  color: rgba(var(--color-sf-success-text));
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active.e-info,
.e-btn-group input:active + label.e-btn.e-info,
.e-btn-group input:checked + label.e-btn.e-info,
.e-css.e-btn-group .e-btn:active.e-info,
.e-css.e-btn-group input:active + label.e-btn.e-info,
.e-css.e-btn-group input:checked + label.e-btn.e-info {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-info-text), 0.12)), to(rgba(var(--color-sf-info-text), 0.12))), rgba(var(--color-sf-info));
  background: linear-gradient(0deg, rgba(var(--color-sf-info-text), 0.12), rgba(var(--color-sf-info-text), 0.12)), rgba(var(--color-sf-info));
  color: rgba(var(--color-sf-info-text));
  border-color: rgba(var(--color-sf-info));
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active.e-warning,
.e-btn-group input:active + label.e-btn.e-warning,
.e-btn-group input:checked + label.e-btn.e-warning,
.e-css.e-btn-group .e-btn:active.e-warning,
.e-css.e-btn-group input:active + label.e-btn.e-warning,
.e-css.e-btn-group input:checked + label.e-btn.e-warning {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-warning-text), 0.12)), to(rgba(var(--color-sf-warning-text), 0.12))), rgba(var(--color-sf-warning));
  background: linear-gradient(0deg, rgba(var(--color-sf-warning-text), 0.12), rgba(var(--color-sf-warning-text), 0.12)), rgba(var(--color-sf-warning));
  border-color: rgba(var(--color-sf-warning));
  color: rgba(var(--color-sf-warning-text));
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active.e-danger,
.e-btn-group input:active + label.e-btn.e-danger,
.e-btn-group input:checked + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:active.e-danger,
.e-css.e-btn-group input:active + label.e-btn.e-danger,
.e-css.e-btn-group input:checked + label.e-btn.e-danger {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-danger-text), 0.12)), to(rgba(var(--color-sf-danger-text), 0.12))), rgba(var(--color-sf-error));
  background: linear-gradient(0deg, rgba(var(--color-sf-danger-text), 0.12), rgba(var(--color-sf-danger-text), 0.12)), rgba(var(--color-sf-error));
  border-color: rgba(var(--color-sf-error));
  color: rgba(var(--color-sf-danger-text));
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
          box-shadow: none;
}
.e-btn-group .e-btn:active.e-outline,
.e-btn-group input:active + label.e-btn.e-outline,
.e-btn-group input:checked + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:active.e-outline,
.e-css.e-btn-group input:active + label.e-btn.e-outline,
.e-css.e-btn-group input:checked + label.e-btn.e-outline {
  background: rgba(var(--color-sf-on-surface), 0.12);
  border-color: rgba(var(--color-sf-outline));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface));
  box-shadow: none;
}
.e-btn-group .e-btn:active.e-outline.e-primary,
.e-btn-group input:active + label.e-btn.e-outline.e-primary,
.e-btn-group input:checked + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group .e-btn:active.e-outline.e-primary,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-primary {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-on-primary), 0.12)), to(rgba(var(--color-sf-on-primary), 0.12))), rgba(var(--color-sf-primary));
  background: linear-gradient(0deg, rgba(var(--color-sf-on-primary), 0.12), rgba(var(--color-sf-on-primary), 0.12)), rgba(var(--color-sf-primary));
  border-color: rgba(var(--color-sf-primary));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-primary));
  background: rgba(var(--color-sf-primary), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-primary));
  box-shadow: none;
}
.e-btn-group .e-btn:active.e-outline.e-success,
.e-btn-group input:active + label.e-btn.e-outline.e-success,
.e-btn-group input:checked + label.e-btn.e-outline.e-success,
.e-css.e-btn-group .e-btn:active.e-outline.e-success,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-success,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-success {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-success-text), 0.12)), to(rgba(var(--color-sf-success-text), 0.12))), rgba(var(--color-sf-success));
  background: linear-gradient(0deg, rgba(var(--color-sf-success-text), 0.12), rgba(var(--color-sf-success-text), 0.12)), rgba(var(--color-sf-success));
  border-color: rgba(var(--color-sf-success));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-success-text));
  background: rgba(var(--color-sf-success), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-success));
  box-shadow: none;
}
.e-btn-group .e-btn:active.e-outline.e-info,
.e-btn-group input:active + label.e-btn.e-outline.e-info,
.e-btn-group input:checked + label.e-btn.e-outline.e-info,
.e-css.e-btn-group .e-btn:active.e-outline.e-info,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-info,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-info {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-info-text), 0.12)), to(rgba(var(--color-sf-info-text), 0.12))), rgba(var(--color-sf-info));
  background: linear-gradient(0deg, rgba(var(--color-sf-info-text), 0.12), rgba(var(--color-sf-info-text), 0.12)), rgba(var(--color-sf-info));
  border-color: rgba(var(--color-sf-info));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-info-text));
  background: rgba(var(--color-sf-info), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-info));
  box-shadow: none;
}
.e-btn-group .e-btn:active.e-outline.e-warning,
.e-btn-group input:active + label.e-btn.e-outline.e-warning,
.e-btn-group input:checked + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group .e-btn:active.e-outline.e-warning,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-warning {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-warning-text), 0.12)), to(rgba(var(--color-sf-warning-text), 0.12))), rgba(var(--color-sf-warning));
  background: linear-gradient(0deg, rgba(var(--color-sf-warning-text), 0.12), rgba(var(--color-sf-warning-text), 0.12)), rgba(var(--color-sf-warning));
  border-color: rgba(var(--color-sf-warning));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-warning-text));
  background: rgba(var(--color-sf-warning), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-warning));
  box-shadow: none;
}
.e-btn-group .e-btn:active.e-outline.e-danger,
.e-btn-group input:active + label.e-btn.e-outline.e-danger,
.e-btn-group input:checked + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:active.e-outline.e-danger,
.e-css.e-btn-group input:active + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group input:checked + label.e-btn.e-outline.e-danger {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(var(--color-sf-danger-text), 0.12)), to(rgba(var(--color-sf-danger-text), 0.12))), rgba(var(--color-sf-error));
  background: linear-gradient(0deg, rgba(var(--color-sf-danger-text), 0.12), rgba(var(--color-sf-danger-text), 0.12)), rgba(var(--color-sf-error));
  border-color: rgba(var(--color-sf-error));
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-danger-text));
  background: rgba(var(--color-sf-error), 0.12);
  border: 1px solid rgba(var(--color-sf-outline));
  color: rgba(var(--color-sf-error));
  box-shadow: none;
}
.e-btn-group .e-btn:disabled,
.e-btn-group input:disabled + label.e-btn,
.e-css.e-btn-group .e-btn:disabled,
.e-css.e-btn-group input:disabled + label.e-btn {
  background-color: rgba(var(--color-sf-secondary), 0.12);
  border-color: rgba(var(--color-sf-secondary), 0);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
}
.e-btn-group .e-btn:disabled.e-primary,
.e-btn-group input:disabled + label.e-btn.e-primary,
.e-css.e-btn-group .e-btn:disabled.e-primary,
.e-css.e-btn-group input:disabled + label.e-btn.e-primary {
  background: rgba(var(--color-sf-secondary), 0.12);
  border-color: rgba(var(--color-sf-secondary), 0);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-primary));
}
.e-btn-group .e-btn:disabled.e-success,
.e-btn-group input:disabled + label.e-btn.e-success,
.e-css.e-btn-group .e-btn:disabled.e-success,
.e-css.e-btn-group input:disabled + label.e-btn.e-success {
  background: rgba(var(--color-sf-secondary), 0.12);
  border-color: rgba(var(--color-sf-secondary), 0);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-success));
}
.e-btn-group .e-btn:disabled.e-info,
.e-btn-group input:disabled + label.e-btn.e-info,
.e-css.e-btn-group .e-btn:disabled.e-info,
.e-css.e-btn-group input:disabled + label.e-btn.e-info {
  background: rgba(var(--color-sf-secondary), 0.12);
  border-color: rgba(var(--color-sf-secondary), 0);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-info));
}
.e-btn-group .e-btn:disabled.e-warning,
.e-btn-group input:disabled + label.e-btn.e-warning,
.e-css.e-btn-group .e-btn:disabled.e-warning,
.e-css.e-btn-group input:disabled + label.e-btn.e-warning {
  background: rgba(var(--color-sf-secondary), 0.12);
  border-color: rgba(var(--color-sf-secondary), 0);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-warning));
}
.e-btn-group .e-btn:disabled.e-danger,
.e-btn-group input:disabled + label.e-btn.e-danger,
.e-css.e-btn-group .e-btn:disabled.e-danger,
.e-css.e-btn-group input:disabled + label.e-btn.e-danger {
  background: rgba(var(--color-sf-secondary), 0.12);
  border-color: rgba(var(--color-sf-secondary), 0);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-error));
}
.e-btn-group .e-btn:disabled.e-link,
.e-btn-group input:disabled + label.e-btn.e-link,
.e-css.e-btn-group .e-btn:disabled.e-link,
.e-css.e-btn-group input:disabled + label.e-btn.e-link {
  color: rgba(var(--color-sf-on-surface), 0.38);
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-decoration: none;
  border-color: transparent;
}
.e-btn-group .e-btn:disabled.e-outline,
.e-btn-group input:disabled + label.e-btn.e-outline,
.e-css.e-btn-group .e-btn:disabled.e-outline,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline {
  background: transparent;
  border-color: rgba(var(--color-sf-secondary), 0.12);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-outline));
}
.e-btn-group .e-btn:disabled.e-outline.e-primary,
.e-btn-group input:disabled + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-primary,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-primary {
  background: transparent;
  border-color: rgba(var(--color-sf-secondary), 0.12);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-primary));
}
.e-btn-group .e-btn:disabled.e-outline.e-success,
.e-btn-group input:disabled + label.e-btn.e-outline.e-success,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-success,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-success {
  background: transparent;
  border-color: rgba(var(--color-sf-secondary), 0.12);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-success));
}
.e-btn-group .e-btn:disabled.e-outline.e-info,
.e-btn-group input:disabled + label.e-btn.e-outline.e-info,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-info,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-info {
  background: transparent;
  border-color: rgba(var(--color-sf-secondary), 0.12);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-info));
}
.e-btn-group .e-btn:disabled.e-outline.e-warning,
.e-btn-group input:disabled + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-warning,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-warning {
  background: transparent;
  border-color: rgba(var(--color-sf-secondary), 0.12);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-warning));
}
.e-btn-group .e-btn:disabled.e-outline.e-danger,
.e-btn-group input:disabled + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group .e-btn:disabled.e-outline.e-danger,
.e-css.e-btn-group input:disabled + label.e-btn.e-outline.e-danger {
  background: transparent;
  border-color: rgba(var(--color-sf-secondary), 0.12);
  -webkit-box-shadow: none;
          box-shadow: none;
  color: rgba(var(--color-sf-on-surface), 0.38);
  border-color: rgba(var(--color-sf-error));
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type),
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type) {
  border-left: transparent;
  border-right: transparent;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:first-of-type,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:first-of-type {
  border-right: transparent;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:last-of-type,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-outline:last-of-type {
  border-left: transparent;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline {
  border-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary {
  border-color: rgba(var(--color-sf-primary));
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-success,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-success {
  border-color: rgba(var(--color-sf-success));
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-info,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-info {
  border-color: rgba(var(--color-sf-info));
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning {
  border-color: rgba(var(--color-sf-warning));
  outline-color: inherit;
}
.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-css.e-btn-group:not(.e-vertical):not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger {
  border-color: rgba(var(--color-sf-error));
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type),
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-outline:not(:first-of-type):not(:last-of-type) {
  border-bottom: transparent;
  border-top: transparent;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-outline:first-of-type,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-outline:first-of-type {
  border-bottom: transparent;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-outline:last-of-type,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-outline:last-of-type {
  border-top: transparent;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline {
  border-color: linear-gradient(0deg, rgba(var(--color-sf-primary), 0.05), rgba(var(--color-sf-primary), 0.05));
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-primary,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-primary {
  border-color: rgba(var(--color-sf-primary));
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-success,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-success,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-success {
  border-color: rgba(var(--color-sf-success));
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-info,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-info,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-info {
  border-color: rgba(var(--color-sf-info));
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-warning,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-warning {
  border-color: rgba(var(--color-sf-warning));
  outline-color: inherit;
}
.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger,
.e-css.e-btn-group.e-vertical:not(.e-rtl) .e-btn:focus.e-outline.e-danger,
.e-css.e-btn-group.e-vertical:not(.e-rtl) input:focus + label.e-btn.e-outline.e-danger {
  border-color: rgba(var(--color-sf-error));
  outline-color: inherit;
}
.e-btn-group.e-rtl .e-btn:not(:first-of-type):not(:last-of-type),
.e-css.e-btn-group.e-rtl .e-btn:not(:first-of-type):not(:last-of-type) {
  border-left: transparent;
  border-right: transparent;
}
.e-btn-group.e-rtl .e-btn:first-of-type,
.e-css.e-btn-group.e-rtl .e-btn:first-of-type {
  border-left: transparent;
}
.e-btn-group.e-rtl .e-btn:last-of-type,
.e-css.e-btn-group.e-rtl .e-btn:last-of-type {
  border-right: transparent;
}

.e-bigger .e-btn-group .e-btn-icon,
.e-bigger.e-btn-group .e-btn-icon,
.e-bigger .e-css.e-btn-group .e-btn-icon,
.e-bigger.e-css.e-btn-group .e-btn-icon {
  font-size: 20px;
}

/* stylelint-disable property-no-vendor-prefix */
@-webkit-keyframes material-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes material-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes fabric-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fabric-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.e-progress-btn {
  position: relative;
}
.e-progress-btn .e-progress {
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
.e-progress-btn .e-spinner {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.e-progress-btn .e-btn-content {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.e-progress-btn.e-vertical .e-progress {
  bottom: 0;
  height: 0%;
  top: auto;
  width: 100%;
}
.e-progress-btn.e-progress-top .e-progress {
  height: 10%;
}
.e-progress-btn.e-progress-bottom .e-progress {
  bottom: -1px;
  height: 10%;
  top: auto;
}
.e-progress-btn.e-progress-active {
  overflow: hidden;
  pointer-events: none;
}
.e-progress-btn.e-progress-active .e-progress {
  opacity: 1;
}
.e-progress-btn.e-progress-active .e-animate-end {
  opacity: 0;
}
.e-progress-btn.e-spin-top, .e-progress-btn.e-spin-bottom {
  -webkit-transition: padding 0.3s linear;
  transition: padding 0.3s linear;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-btn-content,
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner, .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-btn-content,
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner {
  display: block;
  line-height: 1;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-center .e-spinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner {
  padding-right: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner .e-spinner-inner {
  top: -1px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 12px;
  padding-right: 0;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner {
  padding-left: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner .e-spinner-inner {
  top: -1px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top {
  padding-bottom: 16px;
  padding-top: 16px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner {
  padding-bottom: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom {
  padding-bottom: 16px;
  padding-top: 16px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner {
  padding-top: 12px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round) .e-spinner {
  padding-right: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 10px;
  padding-right: 0;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round) .e-spinner {
  padding-left: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top {
  padding-bottom: 14px;
  padding-top: 14px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top .e-spinner {
  padding-bottom: 10px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom {
  padding-bottom: 14px;
  padding-top: 14px;
}
.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom .e-spinner {
  padding-top: 10px;
}
.e-progress-btn.e-round-corner {
  border-radius: 5em;
  outline: none;
  overflow: hidden;
}
.e-progress-btn.e-rtl .e-progress {
  left: auto;
  right: 0;
}
.e-progress-btn.e-rtl.e-vertical .e-progress {
  bottom: auto;
  top: 0;
}
.e-progress-btn .e-spinner-pane {
  height: auto;
  position: relative;
  width: auto;
}
.e-progress-btn .e-spinner-pane .e-spinner-inner {
  left: auto;
  position: relative;
  top: auto;
  -webkit-transform: none;
          transform: none;
}
.e-progress-btn .e-cont-animate {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
@-webkit-keyframes ProgressSlideLeft {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0;
  }
}
@keyframes ProgressSlideLeft {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0;
  }
}
@-webkit-keyframes ProgressSlideRight {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: 100%;
    opacity: 0;
  }
}
@keyframes ProgressSlideRight {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: 100%;
    opacity: 0;
  }
}
@-webkit-keyframes ProgressSlideUp {
  from {
    height: auto;
    opacity: 1;
    top: 0;
  }
  to {
    height: auto;
    opacity: 0;
    top: -100px;
  }
}
@keyframes ProgressSlideUp {
  from {
    height: auto;
    opacity: 1;
    top: 0;
  }
  to {
    height: auto;
    opacity: 0;
    top: -100px;
  }
}
@-webkit-keyframes ProgressSlideDown {
  from {
    height: auto;
    opacity: 1;
    top: 0;
  }
  to {
    height: auto;
    opacity: 0;
    top: 100px;
  }
}
@keyframes ProgressSlideDown {
  from {
    height: auto;
    opacity: 1;
    top: 0;
  }
  to {
    height: auto;
    opacity: 0;
    top: 100px;
  }
}
@-webkit-keyframes ProgressZoomIn {
  from {
    opacity: 1;
    -webkit-transform: translate(0, 0) scale(1);
            transform: translate(0, 0) scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: translate(0, 0) scale(3);
            transform: translate(0, 0) scale(3);
  }
}
@keyframes ProgressZoomIn {
  from {
    opacity: 1;
    -webkit-transform: translate(0, 0) scale(1);
            transform: translate(0, 0) scale(1);
  }
  to {
    opacity: 0;
    -webkit-transform: translate(0, 0) scale(3);
            transform: translate(0, 0) scale(3);
  }
}
@-webkit-keyframes ProgressZoomOut {
  from {
    -webkit-transform: translate(0, 0) scale(1);
            transform: translate(0, 0) scale(1);
  }
  to {
    -webkit-transform: translate(0, 0) scale(0);
            transform: translate(0, 0) scale(0);
  }
}
@keyframes ProgressZoomOut {
  from {
    -webkit-transform: translate(0, 0) scale(1);
            transform: translate(0, 0) scale(1);
  }
  to {
    -webkit-transform: translate(0, 0) scale(0);
            transform: translate(0, 0) scale(0);
  }
}

/*! Progress Button Theme */
.e-progress-btn .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-on-surface));
  stroke: rgba(var(--color-sf-on-surface));
}
.e-progress-btn .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-on-surface));
}
.e-progress-btn.e-primary .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-on-primary));
  stroke: rgba(var(--color-sf-on-primary));
}
.e-progress-btn.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-on-primary));
}
.e-progress-btn.e-primary.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-primary));
  stroke: rgba(var(--color-sf-primary));
}
.e-progress-btn.e-primary.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-white));
}
.e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-primary));
  stroke: rgba(var(--color-sf-primary));
}
.e-progress-btn.e-primary.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-primary.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-on-primary));
}
.e-progress-btn.e-success .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-success-text));
  stroke: rgba(var(--color-sf-success-text));
}
.e-progress-btn.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-success-text));
}
.e-progress-btn.e-success.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-success));
  stroke: rgba(var(--color-sf-success));
}
.e-progress-btn.e-success.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-success));
}
.e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-success));
  stroke: rgba(var(--color-sf-success));
}
.e-progress-btn.e-success.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-success.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-success-text));
}
.e-progress-btn.e-info .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-info-text));
  stroke: rgba(var(--color-sf-info-text));
}
.e-progress-btn.e-info .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-info-text));
}
.e-progress-btn.e-info.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-info));
  stroke: rgba(var(--color-sf-info));
}
.e-progress-btn.e-info.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-info));
}
.e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-info));
  stroke: rgba(var(--color-sf-info));
}
.e-progress-btn.e-info.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-info.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-info-text));
}
.e-progress-btn.e-warning .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-warning-text));
  stroke: rgba(var(--color-sf-warning-text));
}
.e-progress-btn.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-warning-text));
}
.e-progress-btn.e-warning.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-warning));
  stroke: rgba(var(--color-sf-warning));
}
.e-progress-btn.e-warning.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle, .e-progress-btn.e-warning.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-circle {
  stroke: rgba(var(--color-sf-warning));
}
.e-progress-btn.e-warning.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-warning));
}
.e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-warning));
  stroke: rgba(var(--color-sf-warning));
}
.e-progress-btn.e-warning.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-warning.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-warning-text));
}
.e-progress-btn.e-danger .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-danger-text));
  stroke: rgba(var(--color-sf-danger-text));
}
.e-progress-btn.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-danger-text));
}
.e-progress-btn.e-danger.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-error));
  stroke: rgba(var(--color-sf-error));
}
.e-progress-btn.e-danger.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:hover.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-flat:focus.e-flat .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-error));
}
.e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-error));
  stroke: rgba(var(--color-sf-error));
}
.e-progress-btn.e-danger.e-outline:hover .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-danger.e-outline:focus .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-danger-text));
}
.e-progress-btn.e-outline.e-primary .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-primary .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-primary));
  stroke: rgba(var(--color-sf-primary));
}
.e-progress-btn.e-outline.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-primary .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-primary));
}
.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-success .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-success));
  stroke: rgba(var(--color-sf-success));
}
.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-success));
}
.e-progress-btn.e-outline.e-info .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-info .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-info));
  stroke: rgba(var(--color-sf-info));
}
.e-progress-btn.e-outline.e-info .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-info .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-info));
}
.e-progress-btn.e-outline.e-warning .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-warning .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-warning));
  stroke: rgba(var(--color-sf-warning));
}
.e-progress-btn.e-outline.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-warning .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-warning));
}
.e-progress-btn.e-outline.e-danger .e-spinner-pane .e-spinner-inner svg, .e-progress-btn.e-flat.e-danger .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-error));
  stroke: rgba(var(--color-sf-error));
}
.e-progress-btn.e-outline.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-arc, .e-progress-btn.e-flat.e-danger .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-error));
}
.e-progress-btn.e-link .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-info));
  stroke: rgba(var(--color-sf-info));
}
.e-progress-btn.e-link .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-info));
}

.e-progress {
  background: rgba(var(--color-sf-on-surface), 0.12);
}

*.e-primary .e-progress,
*.e-success .e-progress,
*.e-danger .e-progress {
  background: rgba(var(--color-sf-on-surface), 0.12);
}

*.e-info .e-progress {
  background: rgba(var(--color-sf-on-surface), 0.12);
}

*.e-flat.e-primary .e-progress,
*.e-flat:not(:focus).e-primary .e-progress {
  background: rgba(var(--color-sf-primary), 0.12);
}
*.e-flat.e-success .e-progress,
*.e-flat:not(:focus).e-success .e-progress {
  background: rgba(var(--color-sf-success), 0.12);
}
*.e-flat.e-info .e-progress,
*.e-flat:not(:focus).e-info .e-progress {
  background: rgba(var(--color-sf-info), 0.12);
}
*.e-flat.e-warning .e-progress,
*.e-flat:not(:focus).e-warning .e-progress {
  background: rgba(var(--color-sf-warning), 0.12);
}
*.e-flat.e-danger .e-progress,
*.e-flat:not(:focus).e-danger .e-progress {
  background: rgba(var(--color-sf-error), 0.12);
}

.e-progress-btn.e-outline .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-on-surface));
  stroke: rgba(var(--color-sf-on-surface));
}

.e-progress-btn.e-outline:focus .e-spinner-pane .e-spinner-inner svg {
  fill: rgba(var(--color-sf-on-surface));
  stroke: rgba(var(--color-sf-on-surface));
}

.e-progress-btn.e-outline.e-success .e-spinner-pane .e-spinner-inner svg .e-path-arc {
  stroke: rgba(var(--color-sf-on-surface));
}

*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round) .e-spinner {
  padding-right: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 14px;
  padding-right: 0;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round) .e-spinner {
  padding-left: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top {
  padding-bottom: 20px;
  padding-top: 20px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-top .e-spinner {
  padding-bottom: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom {
  padding-bottom: 20px;
  padding-top: 20px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-spin-bottom .e-spinner {
  padding-top: 14px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round) .e-spinner {
  padding-right: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-left:not(.e-round).e-rtl .e-spinner {
  padding-left: 12px;
  padding-right: 0;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round) .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round) .e-spinner {
  padding-left: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round).e-rtl .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-right:not(.e-round).e-rtl .e-spinner {
  padding-left: 0;
  padding-right: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top {
  padding-bottom: 16px;
  padding-top: 16px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-top .e-spinner {
  padding-bottom: 12px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom {
  padding-bottom: 16px;
  padding-top: 16px;
}
*.e-bigger .e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom .e-spinner,
*.e-bigger.e-progress-btn.e-progress-active:not(.e-hide-spinner).e-small.e-spin-bottom .e-spinner {
  padding-top: 12px;
}
@use "sass:map";
@use "sass:math";
@use "@angular/material" as mat;
@use "theming/dlc-color-vars";
@use "core";
@use "theming";
@forward "theming/dlc-utils";
@forward "../components/component-vars";
@forward "core/dlc-animation-vars";

/**
 * Extends material theme
 */
//@function dlc-extend-material-theme-config($theme) {
//  $palettes: map.get($theme, color);
//  $primary: map.get($palettes, primary);
//  $accent: map.get($palettes, accent);
//  $warn: map.get($palettes, warn);
//
//  @return map.merge(
//    $theme,
//    (
//      default-contrast: mat.get-contrast-color-from-palette($primary, 600),
//      primary-contrast: mat.get-contrast-color-from-palette($primary, 600),
//      accent-contrast: mat.get-contrast-color-from-palette($accent, 600),
//      warn-contrast: mat.get-contrast-color-from-palette($warn, 600)
//    )
//  );
//}
/**
  * Include this mixin only once at the core of your app.
  * Styles and CSS vars will be applied to the <html> element
  * and the <body> element.
  *
  * Input a partial font config and get a full font config back.
  * If typography params are not provided in theme, set defaults.
  * @example
  * $font-config: (
  *   font-basis: 16,
  *   font-family: "Inter", sans-serif,
  *   base-font-size: 14px,
  *   base-line-height: 1.2rem,
  *   base-font-weight: 200,
  * )
  */
@mixin dlc-core($font-config) {
  $font-config: core.create-base-typography-config($font-config);

  $font-basis: map.get($font-config, font-basis);

  html {
    // font-basis for rem calculations
    font-size: #{$font-basis}px;
    height: 100%;
  }

  body {
    // base font styles
    //font-family: map.get($font-config, font-family);
    //font-weight: map.get($font-config, base-font-weight);
    //line-height: map.get($font-config, base-line-height);
    //font-size: map.get($font-config, base-font-size);

    // common vars for all themes, components, directives, etc.
    @include core.base-typography-vars($font-config);
    @include core.pixes-to-rem-vars($font-config);
    @include core.dlc-animation-vars();
    @include core.common-css-vars();
    @include core.dlc-global-styles();

    height: 100%;

    margin: 0;
    font-family: Inter, sans-serif;
    font-weight: 300;
    background-color: var(--mat-sidenav-content-background-color);

    //  BEGIN MATERIAL OVERRIDES
    --mdc-outlined-text-field-container-shape: var(
      --dlc-form-field-border-radius,
      0.5rem
    );

    .mdc-text-field--outlined:not(.mdc-text-field--disabled)
      .mdc-notched-outline
      .mdc-notched-outline__notch {
      border-right: 0;
    }

    .mat-icon.material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
    }

    .mat-icon.material-symbols-filled {
      font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
    }

    // END MATERIAL OVERRIDES

    .e-sidebar {
      background-color: var(
        --mat-sidenav-container-background-color,
        var(--mat-app-surface)
      );

      .dlc-toggle-button {
        @apply w-full  overflow-hidden;

        button {
          @apply inline-flex flex-row justify-start items-center h-[40px] cursor-pointer w-full min-w-[220px];
          .mat-icon {
            @apply mx-[18px] my-[8px];
          }
        }
      }
    }

    .e-template-list {
      .e-list-item {
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        min-width: 220px;

        .mat-icon {
          margin-right: 18px;
        }
      }
    }
  }
}

/**
 * Expecting structure like this:
 * (
 *  color: (
 *    primary: $ec-blue-primary-palette,
 *    accent: $ec-blue-accent-palette,
 *    warn: $ec-warn-palette
 *  ),
 * )
 */
@mixin dlc-light-theme-vars($theme) {
  @include dlc-color-vars.theme-color-vars($theme);
  @include theming.css-vars-light();

  // Background for whole app
  //background: #f8f8f8 !important;
  color: var(--dlc-color-text);
}

/**
 * Expecting structure like this:
 *
 * $font-family: Inter, sans-serif;
 *
 * (
 *  color: (
 *    primary: $ec-blue-primary-palette,
 *    accent: $ec-blue-accent-palette,
 *    warn: $ec-warn-palette
 *  )
 * )
 */
@mixin dlc-dark-theme-vars($theme) {
  @include dlc-color-vars.theme-color-vars($theme);
  @include theming.css-vars-dark();
  // Background for whole app
  //background: #333333 !important;
  color: var(--dlc-color-text);
}

@mixin dlc-vars {
  .dlc-rounded-button {
    --dlc-rounded-button-font-weight: 400;
    --dlc-rounded-button-font-size: 0.75rem;
    --dlc-rounded-button-line-height: 0.75rem;
    --dlc-rounded-button-padding-top: 0.375rem;
    --dlc-rounded-button-padding-bottom: 0.375rem;
    --dlc-rounded-button-padding-left: 0.75rem;
    --dlc-rounded-button-padding-right: 0.75rem;
    --dlc-rounded-button-border-radius: 9999px;
    --dlc-rounded-button-icon-size: 1rem;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    vertical-align: middle;
  }

  .dlc-square-button {
    --dlc-square-button-font-weight: 600;
    --dlc-square-button-font-size: 0.75rem;
    --dlc-square-button-line-height: 1rem;
    --dlc-square-button-padding-top: 0.375rem;
    --dlc-square-button-padding-bottom: 0.375rem;
    --dlc-square-button-padding-left: 0.75rem;
    --dlc-square-button-padding-right: 0.75rem;
    --dlc-square-button-border-radius: 4px;
    --dlc-rounded-button-icon-size: 1rem;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  button {
    background: transparent;
    border: 0;
  }
}

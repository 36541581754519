@mixin dlc-global-styles() {
  .dlc-global-dialog-background {
    background-color: var(--dlc-color-mat-dialog-background);
  }

  .dlc-global-mobile-page {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex: 1 1 auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .dlc-global-textarea-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: var(--16px);
    flex: 1 1 auto;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: var(--dlc-border-radius);
    min-height: 150px;

    .dlc-global-textarea-container-element {
      display: flex;
      position: relative;
      background-color: transparent;
      line-height: 1.5;
      border: none;
      z-index: 10;
      font-family: var(--dlc-base-font-family);
      //font-size: var(--dlc-form-field-input-font-size);
      font-size: var(--18px);
      font-weight: 200;
      color: var(--dlc-color-text);
      flex: 1 1 auto;
      resize: none;
      vertical-align: middle;

      &:focus-visible {
        outline: none;
      }
    }

    &.with-controls {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

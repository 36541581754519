// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "ec-themes";
//@use "mat-m3-themes";

// TailwindCSS
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  table {
    border-collapse: collapse;
    font-weight: 200;

    th {
      position: relative;
    }

    th,
    td {
      padding: 10px;
    }
  }

  p {
    @apply text-sm font-light leading-7 mb-2;
  }

  h1 {
    @apply text-4xl font-extrabold mb-5;
  }

  h2 {
    @apply text-2xl font-bold mb-4;
  }

  h3 {
    @apply text-xl font-bold mb-3;
  }

  h4 {
    @apply text-lg font-bold mb-2;
  }

  h5 {
    @apply text-base font-bold mb-2;
  }

  h6 {
    @apply text-sm font-bold mb-1;
  }

  ul,
  ol {
    @apply list-disc pl-8 mb-5 text-sm font-light;

    li {
      @apply mb-2;
    }
  }
}

@include ec-themes.ec-theme-mixin();

input.e-rte-backgroundcolor-element {
  background: transparent;
  outline: none;
  position: absolute;
  transform: translateX(-200%);
}

input.e-rte-fontcolor-element {
  background: transparent;
  outline: none;
  position: absolute;
  transform: translateX(-200%);
}

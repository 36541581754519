@mixin dlc-animation-vars {
  --dlc-color-black-12-opacity: rgba(255, 255, 255, 0.12);
  --dlc-transition-timing-function: ease-in-out;
  --dlc-transition-duration: 200ms;
  --dlc-transition-delay: 0ms;

  @keyframes inlineFadeInFromNone {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: inline-flex;
      opacity: 0;
    }

    100% {
      display: inline-flex;
      opacity: 1;
      z-index: 10;
    }
  }

  @keyframes inlineFadeOutToNone {
    0% {
      display: inline-flex;
      opacity: 1;
    }

    99% {
      display: inline-flex;
      opacity: 0;
    }

    100% {
      display: none;
      opacity: 0;
      z-index: 0;
    }
  }
}

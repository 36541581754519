@use "sass:math";
@use "sass:map";

@mixin pixes-to-rem-vars($theme) {
  $font-basis: map.get($theme, font-basis);

  --0px: 0;
  --1px: #{math.div(1, $font-basis)}rem;
  --2px: #{math.div(2, $font-basis)}rem;
  --3px: #{math.div(3, $font-basis)}rem;
  --4px: #{math.div(4, $font-basis)}rem;
  --5px: #{math.div(5, $font-basis)}rem;
  --6px: #{math.div(6, $font-basis)}rem;
  --7px: #{math.div(7, $font-basis)}rem;
  --8px: #{math.div(8, $font-basis)}rem;
  --9px: #{math.div(9, $font-basis)}rem;
  --10px: #{math.div(10, $font-basis)}rem;
  --11px: #{math.div(11, $font-basis)}rem;
  --12px: #{math.div(12, $font-basis)}rem;
  --13px: #{math.div(13, $font-basis)}rem;
  --14px: #{math.div(14, $font-basis)}rem;
  --15px: #{math.div(15, $font-basis)}rem;
  --16px: #{math.div(16, $font-basis)}rem;
  --17px: #{math.div(17, $font-basis)}rem;
  --18px: #{math.div(18, $font-basis)}rem;
  --19px: #{math.div(19, $font-basis)}rem;
  --20px: #{math.div(20, $font-basis)}rem;
  --21px: #{math.div(21, $font-basis)}rem;
  --22px: #{math.div(22, $font-basis)}rem;
  --23px: #{math.div(23, $font-basis)}rem;
  --24px: #{math.div(24, $font-basis)}rem;
  --25px: #{math.div(25, $font-basis)}rem;
  --26px: #{math.div(26, $font-basis)}rem;
  --27px: #{math.div(27, $font-basis)}rem;
  --28px: #{math.div(28, $font-basis)}rem;
  --29px: #{math.div(29, $font-basis)}rem;
  --30px: #{math.div(30, $font-basis)}rem;
  --31px: #{math.div(31, $font-basis)}rem;
  --32px: #{math.div(32, $font-basis)}rem;
  --33px: #{math.div(33, $font-basis)}rem;
  --34px: #{math.div(34, $font-basis)}rem;
  --35px: #{math.div(35, $font-basis)}rem;
  --36px: #{math.div(36, $font-basis)}rem;
  --37px: #{math.div(37, $font-basis)}rem;
  --38px: #{math.div(38, $font-basis)}rem;
  --39px: #{math.div(39, $font-basis)}rem;
  --40px: #{math.div(40, $font-basis)}rem;
  --41px: #{math.div(41, $font-basis)}rem;
  --42px: #{math.div(42, $font-basis)}rem;
  --43px: #{math.div(43, $font-basis)}rem;
  --44px: #{math.div(44, $font-basis)}rem;
  --45px: #{math.div(45, $font-basis)}rem;
  --46px: #{math.div(46, $font-basis)}rem;
  --47px: #{math.div(47, $font-basis)}rem;
  --48px: #{math.div(48, $font-basis)}rem;
  --49px: #{math.div(49, $font-basis)}rem;
  --50px: #{math.div(50, $font-basis)}rem;
  --51px: #{math.div(51, $font-basis)}rem;
  --52px: #{math.div(52, $font-basis)}rem;
  --53px: #{math.div(53, $font-basis)}rem;
  --54px: #{math.div(54, $font-basis)}rem;
  --55px: #{math.div(55, $font-basis)}rem;
  --56px: #{math.div(56, $font-basis)}rem;
  --57px: #{math.div(57, $font-basis)}rem;
  --58px: #{math.div(58, $font-basis)}rem;
  --59px: #{math.div(59, $font-basis)}rem;
  --60px: #{math.div(60, $font-basis)}rem;
  --61px: #{math.div(61, $font-basis)}rem;
  --62px: #{math.div(62, $font-basis)}rem;
  --63px: #{math.div(63, $font-basis)}rem;
  --64px: #{math.div(64, $font-basis)}rem;
  --65px: #{math.div(65, $font-basis)}rem;
  --66px: #{math.div(66, $font-basis)}rem;
  --67px: #{math.div(67, $font-basis)}rem;
  --68px: #{math.div(68, $font-basis)}rem;
  --69px: #{math.div(69, $font-basis)}rem;
  --70px: #{math.div(70, $font-basis)}rem;
  --71px: #{math.div(71, $font-basis)}rem;
  --72px: #{math.div(72, $font-basis)}rem;
  --73px: #{math.div(73, $font-basis)}rem;
  --74px: #{math.div(74, $font-basis)}rem;
  --75px: #{math.div(75, $font-basis)}rem;
  --76px: #{math.div(76, $font-basis)}rem;
  --77px: #{math.div(77, $font-basis)}rem;
  --78px: #{math.div(78, $font-basis)}rem;
  --79px: #{math.div(79, $font-basis)}rem;
  --80px: #{math.div(80, $font-basis)}rem;
  --81px: #{math.div(81, $font-basis)}rem;
  --82px: #{math.div(82, $font-basis)}rem;
  --83px: #{math.div(83, $font-basis)}rem;
  --84px: #{math.div(84, $font-basis)}rem;
  --85px: #{math.div(85, $font-basis)}rem;
  --86px: #{math.div(86, $font-basis)}rem;
  --87px: #{math.div(87, $font-basis)}rem;
  --88px: #{math.div(88, $font-basis)}rem;
  --89px: #{math.div(89, $font-basis)}rem;
  --90px: #{math.div(90, $font-basis)}rem;
  --91px: #{math.div(91, $font-basis)}rem;
  --92px: #{math.div(92, $font-basis)}rem;
  --93px: #{math.div(93, $font-basis)}rem;
  --94px: #{math.div(94, $font-basis)}rem;
  --95px: #{math.div(95, $font-basis)}rem;
  --96px: #{math.div(96, $font-basis)}rem;
  --97px: #{math.div(97, $font-basis)}rem;
  --98px: #{math.div(98, $font-basis)}rem;
  --99px: #{math.div(99, $font-basis)}rem;
  --100px: #{math.div(100, $font-basis)}rem;

  --180px: #{math.div(180, $font-basis)}rem;
}

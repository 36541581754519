@mixin ec-global-styles() {
  .ec-wrong-color {
    color: var(--ec-color-wrong-answer);
  }

  .ec-correct-color {
    color: var(--ec-color-correct-answer);
  }

  /**
   * Chip style
   * Used in libs/evolving-cognition/ui/src/lib/quiz/quiz-review/ec-quiz-review.component.html
   */
  .ec-chip-wrong-answers-count {
    @apply rounded-l-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-xs font-normal border;
    color: var(--ec-color-wrong-answer);
    border-color: var(--ec-color-wrong-answer);
    background-color: var(--ec-color-background-wrong-answer);

    &__bold {
      @apply font-bold;
    }

    &__light {
      @apply font-light;
    }
  }

  /**
  * Chip style
  * Used in libs/evolving-cognition/ui/src/lib/quiz/quiz-review/ec-quiz-review.component.html
  */
  .ec-chip-correct-answers-count {
    @apply rounded-r-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-xs font-normal border;
    color: var(--ec-color-correct-answer);
    border-color: var(--ec-color-correct-answer);
    background-color: var(--ec-color-background-correct-answer);

    &__bold {
      @apply font-bold;
    }

    &__light {
      @apply font-light;
    }
  }

  .ec-chip-wrong-answer {
    @apply rounded-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-xs font-normal border;
    color: var(--ec-color-wrong-answer);
    border-color: var(--ec-color-wrong-answer);

    &_bg {
      background-color: var(--ec-color-background-wrong-answer);
    }

    &__bold {
      @apply font-bold;
    }

    &__light {
      @apply font-light;
    }
  }

  .ec-chip-correct-answer {
    @apply rounded-md whitespace-nowrap mt-0.5 px-2.5 py-1.5 text-xs font-normal border;
    color: var(--ec-color-correct-answer);
    border-color: var(--ec-color-correct-answer);

    &__bg {
      background-color: var(--ec-color-background-correct-answer);
    }

    &__bold {
      @apply font-bold;
    }

    &__light {
      @apply font-light;
    }
  }

  .ec-review-answer {
    @apply rounded-md mt-0.5 px-2.5 py-1.5 text-xs font-normal border border-transparent;

    &__correct {
      border-color: var(--ec-color-correct-answer);
    }
  }

  .mat-mdc-tab-header {
    @apply border-b border-gray-200/25;
  }
}

@mixin css-vars-light() {
  --dlc-color-black-12-opacity: rgba(0, 0, 0, 0.12);
  --dlc-color-text: rgba(0, 0, 0, 0.87);

  --dlc-color-border: #e0e0e0;

  // BUTTON
  --dlc-color-button-disabled: #eeeeee;
  --dlc-color-background-button-disabled: #b0b0b0;
  --dlc-color-background-button-none-hover: #c3c3c3;

  // PANELS
  --dlc-color-background-panel: rgb(255 255 255 / 0.05);
}

@mixin css-vars-dark() {
  --dlc-color-black-12-opacity: rgba(255, 255, 255, 0.12);
  --dlc-color-text: rgba(255, 255, 255, 0.87);

  --dlc-color-border: #6b6b6b;

  // BUTTON
  --dlc-color-background-button-disabled: #757575;
  --dlc-color-background-button-none-hover: #4c4c4c;

  // PANELS
  --dlc-color-background-panel: rgb(255 255 255 / 0.07);
}

@use "@angular/material" as mat;
//@use "@angular/material-experimental" as matx;
//@use "../../../../material" as ngpat-material;
//@use "./dlc-theming";

/* For use in src/lib/core/theming/dlc-palette.scss */

$scale: -3;
//$scale: 0;

$ec-yellow-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$orange-palette,
      //tertiary: mat.$orange-palette
    ),
    density: (
      scale: $scale
    )
  )
);

//$ec-yellow-theme: dlc-theming.dlc-extend-material-theme-config($ec-yellow-theme);

//$ec-yellow-theme: ngpat-material.override-dark-theme-background-with-lightness-saturation(
//  $ec-yellow-theme,
//  map-get($ec-yellow-theme, primary),
//  -45%,
//  // $lightness
//  -50% // $saturation
//);

/* For use in src/lib/core/theming/dlc-palette.scss */

/* For use in src/lib/core/theming/dlc-palette.scss */

// Default theme
$ec-purple-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$violet-palette,
      //tertiary: mat.$magenta-palette
    ),
    density: (
      scale: $scale
    )
  )
);

$ec-study-group-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$violet-palette,
      tertiary: mat.$magenta-palette
    ),
    density: (
      scale: $scale
    )
  )
);

$ec-research-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$cyan-palette,
      tertiary: mat.$orange-palette
    ),
    density: (
      scale: $scale
    )
  )
);

$ec-project-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$blue-palette,
      tertiary: mat.$orange-palette
    ),
    density: (
      scale: $scale
    )
  )
);

$ec-investigation-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$chartreuse-palette,
      tertiary: mat.$spring-green-palette
    ),
    density: (
      scale: $scale
    )
  )
);

//$ec-purple-theme: ngpat-material.override-dark-theme-background-with-lightness-saturation(
//  $ec-purple-theme,
//  map-get($ec-purple-theme, primary),
//  -30%,
//  // $lightness
//  -35% // $saturation
//);

$ec-blue-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$blue-palette,
      //tertiary: mat.$cyan-palette
    ),
    density: (
      scale: $scale
    )
  )
);

//$ec-blue-theme: ngpat-material.override-dark-theme-background-with-lightness-saturation(
//  $ec-blue-theme,
//  map-get($ec-blue-theme, primary),
//  -15%,
//  // $lightness
//  -35% // $saturation
//);

$ec-green-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$spring-green-palette,
      //tertiary: mat.$chartreuse-palette
    ),
    density: (
      scale: $scale
    )
  )
);

//$qk-personal-theme: ngpat-material.override-dark-theme-background-with-lightness-saturation(
//    $qk-personal-theme,
//    map-get($qk-personal-theme, primary),
//    -17%, // $lightness
//    -30% // $saturation
//);

$ec-personal-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette
    ),
    density: (
      scale: $scale
    )
  )
);

$ec-error-theme: mat.define-theme(
  (
    color: (
      theme-type: dark,
      primary: mat.$red-palette
    ),
    density: (
      scale: $scale
    )
  )
);

//$ec-personal-theme-blue-background: mat.define-dark-theme(
//  (
//    color: (
//      primary: $ec-app-primary-palette-blue,
//      accent: $ec-app-accent-palette-blue,
//      warn: $ec-warn-palette
//    ),
//    typography: $typography-config,
//    density: $ec-density
//  )
//);

//$ec-personal-theme: dlc-theming.dlc-extend-material-theme-config($ec-personal-theme);

//$ec-personal-theme: ngpat-material.override-dark-theme-background-with-lightness-saturation(
//  $ec-personal-theme,
//  map-get($ec-personal-theme-blue-background, primary),
//  -25%,
//  // $lightness
//  -40% // $saturation
//);
